import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../stores/context';

const AuthGuard = observer(({ children }) => {
	const {
		authStore: { isLoggedIn },
	} = useStores();

	const navigate = useNavigate();
	const token = window.localStorage.getItem('accessToken');

	useEffect(() => {
		if (!isLoggedIn && !token) {
			navigate('/login', { replace: true });
		}
	}, [isLoggedIn, navigate]);

	return children;
});

AuthGuard.defaultProps = {
	children: null,
};

AuthGuard.propTypes = {
	children: PropTypes.node,
};

export default AuthGuard;
