import { set, toJS } from 'mobx';
import { flow, getEnv } from 'mobx-state-tree';

const UserSubscriptionsStoreActions = (self) => ({
	getAllSubscriptions: flow(function* getAllSubscriptions() {
		const { api } = getEnv(self);

		try {
			self.isLoading = true;
			const { data } = yield api.subscriptions.getAll();

			self.subscriptions = data;
			self.isLoading = false;
			self.isLoaded = true;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	createUserSubscription: flow(function* createUserSubscription(payload) {
		const { api } = getEnv(self);

		try {
			yield api.userSubscriptions.create(payload);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	getAllWithPagination: flow(function* getAllWithPagination() {
		const { api } = getEnv(self);

		try {
			self.isLoading = true;
			const {
				data: { items, meta },
			} = yield api.userSubscriptions.getAllByUserId(self.searchQuery);

			self.meta = {
				...meta,
				columnVisibility: toJS(self.searchQuery.columnVisibility),
				columnOrder: toJS(self.searchQuery.columnOrder),
				columnDimensions: toJS(self.searchQuery.columnDimensions),
				pinnedColumns: toJS(self.searchQuery.pinnedColumns),
			};
			self.items = items;
			self.isLoading = false;
			self.isLoaded = true;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	setSearchQuery: (value) => {
		set(self.searchQuery, value);
		sessionStorage.setItem(
			'userSubscriptionsSearchQuery',
			JSON.stringify(self.searchQuery),
		);
	},
});

export default UserSubscriptionsStoreActions;
