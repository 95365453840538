import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import './index.scss';

function Footer() {
	const { t } = useTranslation();

	return (
		<Stack
			className="footer"
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{ p: '24px 16px 0px', mt: 'auto' }}
		>
			<Typography variant="caption">
				&copy;{new Date().getFullYear()} {t('footer.rights')}
			</Typography>
			<Stack
				spacing={1.5}
				className="footer__links"
				direction="row"
				justifyContent="flex-end"
				alignItems="center"
				flexWrap="wrap"
			>
				<a
					className="footer__link"
					href="https://m.teleshop.pro/terms-and-privacy-policy.html"
					target="_blank"
					rel="noreferrer"
				>
					{t('footer.terms-and-privacy-policy')}
				</a>
				<a
					className="footer__link"
					href="https://m.teleshop.pro/payments.html"
					target="_blank"
					rel="noreferrer"
				>
					{t('footer.payment-terms')}
				</a>
				<a
					className="footer__link"
					href="https://t.me/teleshop_pro_bot?start=64858a2b651657b6380e1be8"
					target="_blank"
					rel="noreferrer"
				>
					{t('footer.support-service')}
				</a>
			</Stack>
		</Stack>
	);
}

export default Footer;
