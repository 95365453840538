import { toJS } from 'mobx';
import { flow, getEnv, getParent } from 'mobx-state-tree';

const OrdersStoreActions = (self) => ({
	getAllWithPagination: flow(function* getAllWithPagination() {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			self.isLoading = true;
			const {
				data: { items, meta },
			} = yield api.orders.getAll(shopId, self.searchQuery);

			self.meta = {
				...meta,
				columnVisibility: toJS(self.searchQuery.columnVisibility),
				columnOrder: toJS(self.searchQuery.columnOrder),
				columnDimensions: toJS(self.searchQuery.columnDimensions),
				pinnedColumns: toJS(self.searchQuery.pinnedColumns),
			};
			self.items = items;
			self.isLoading = false;
			self.isLoaded = true;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	update: flow(function* update(shopId, id, payload) {
		const { api } = getEnv(self);

		try {
			yield api.orders.update(shopId, id, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	updateStatus: flow(function* updateStatus(id, payload) {
		const { api } = getEnv(self);

		try {
			yield api.orders.updateStatus(id, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	updateIsPayedStatus: flow(function* updateIsPayedStatus(id, payload) {
		const { api } = getEnv(self);

		try {
			yield api.orders.updateIsPayedStatus(id, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	exportOrders: flow(function* exportOrders(shopId) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.orders.exportOrders(shopId);

			return data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	setSearchQuery: (value) => {
		const query = { ...self.searchQuery, ...value };
		self.searchQuery = query;
		sessionStorage.setItem('ordersSearchQuery', JSON.stringify(query));
	},

	setCustomerIdFilter: (customerId) => {
		const filters = { ...self.searchQuery.filters, customerId };
		self.searchQuery = { ...self.searchQuery, filters };
		sessionStorage.setItem(
			'ordersSearchQuery',
			JSON.stringify(self.searchQuery),
		);
	},

	setIsLoaded: (value) => {
		self.isLoaded = value;
	},
});

export default OrdersStoreActions;
