import { types } from 'mobx-state-tree';
import { Order } from '../ordersStore/model';
import { OrderBy } from '../sharedModels';

const Customer = types.model('Customer', {
	id: types.maybeNull(types.identifierNumber),
	firstName: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	telegramUsername: types.maybeNull(types.string),
	telegramUserId: types.maybeNull(types.string),
	phoneNumber: types.maybeNull(types.string),
	updatedAt: types.maybeNull(types.string),
	createdAt: types.maybeNull(types.string),
	orders: types.optional(types.array(Order), []),
	billingPhoneNumbers: types.optional(types.array(types.string), []),
	shippingPhoneNumbers: types.optional(types.array(types.string), []),
	emailHistory: types.optional(types.array(types.string), []),
	customerShopsData: types.optional(types.frozen(), []),
});

const CustomerSearchQuery = types.model('CustomerSearchQuery', {
	search: types.optional(types.string, ''),
	page: types.optional(types.number, 1),
	limit: types.optional(types.number, 10),
	order: types.optional(OrderBy, {}),
	columnVisibility: types.optional(types.frozen(), {}),
	columnOrder: types.optional(types.array(types.string), []),
	columnDimensions: types.optional(types.frozen(), {}),
	pinnedColumns: types.optional(types.frozen(), {}),
});

export { Customer, CustomerSearchQuery };
