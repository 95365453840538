const SnackbarActions = (self) => ({
	openSnackbar(values) {
		const {
			open,
			message,
			anchorOrigin,
			variant,
			alert,
			transition,
			close,
			actionButton,
		} = values;

		self.action = !self.action;
		self.open = open || false;
		self.message = message || 'Note archived';
		self.anchorOrigin = anchorOrigin || {
			vertical: 'top',
			horizontal: 'right',
		};
		self.variant = variant || 'default';
		self.alert = {
			color: alert?.color || 'primary',
			variant: alert?.variant || 'filled',
		};
		self.transition = transition || 'Fade';
		self.close = close === false ? close : true;
		self.actionButton = actionButton || false;
	},
	closeSnackbar() {
		self.open = false;
	},
});

export default SnackbarActions;
