import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import LogoMain from './LogoMain';
import LogoIcon from './LogoIcon';

function LogoSection({ isIcon, sx, to }) {
	return (
		<ButtonBase
			disableRipple
			component={Link}
			to={!to ? '/tables/orders' : to}
			sx={sx}
		>
			{isIcon ? <LogoIcon /> : <LogoMain />}
		</ButtonBase>
	);
}

LogoSection.defaultProps = {
	isIcon: false,
	sx: null,
	to: '',
};

LogoSection.propTypes = {
	isIcon: PropTypes.bool,
	sx: PropTypes.instanceOf(Object),
	to: PropTypes.string,
};

export default LogoSection;
