import { types } from 'mobx-state-tree';
import { OrderBy } from '../sharedModels';

const Category = types.model('Category', {
	id: types.maybeNull(types.identifierNumber),
	title: types.maybeNull(types.string),
	orderBy: types.optional(types.string, 'popular'),
	parentId: types.maybeNull(types.number),
	parent: types.maybeNull(types.late(() => Category)),
	subcategories: types.optional(types.array(types.late(() => Category)), []),
	validParentCategories: types.optional(
		types.array(types.late(() => Category)),
		[],
	),
});

const AutocompleteCategory = types.model('AutocompleteCategory', {
	label: types.optional(types.string, ''),
	value: types.maybeNull(types.identifierNumber),
});

const CategorySearchQuery = types.model('CategorySearchQuery', {
	search: types.optional(types.string, ''),
	page: types.optional(types.number, 1),
	limit: types.optional(types.number, 10),
	order: types.optional(OrderBy, {}),
	columnVisibility: types.optional(types.frozen(), {}),
	columnOrder: types.optional(types.array(types.string), []),
	columnDimensions: types.optional(types.frozen(), {}),
	pinnedColumns: types.optional(types.frozen(), {}),
});

const ModalState = types.model('ModalState', {
	action: types.enumeration('Action', ['create']),
});

export { Category, CategorySearchQuery, AutocompleteCategory, ModalState };
