import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react';
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';
import { useStores } from '../../stores/context';

const ThemeCustomization = observer(({ children }) => {
	const {
		appStore: { themeMode },
	} = useStores();

	const mode = themeMode;
	const theme = useMemo(() => Palette(mode), [mode]);
	const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

	const themeOptions = useMemo(
		() => ({
			breakpoints: {
				values: {
					xs: 0,
					sm: 768,
					md: 1024,
					lg: 1266,
					xl: 1440,
				},
			},
			direction: 'ltr',
			mixins: {
				toolbar: {
					minHeight: 60,
					paddingTop: 8,
					paddingBottom: 8,
				},
			},
			palette: theme.palette,
			customShadows: themeCustomShadows,
			typography: Typography,
		}),
		[theme, themeCustomShadows],
	);

	const themes = createTheme(themeOptions);
	themes.components = componentsOverride(themes);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
});

ThemeCustomization.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ThemeCustomization;
