/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Typography,
} from '@mui/material';

// header style
const headerSX = {
	p: 2.5,
	'& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
};

const MainCard = forwardRef(
	(
		{
			border,
			boxShadow,
			children,
			subheader,
			content,
			contentSX,
			darkTitle,
			divider,
			elevation,
			secondary,
			shadow,
			sx,
			title,
			modal,
			...others
		},
		ref,
	) => {
		const theme = useTheme();
		boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

		return (
			<Card
				elevation={elevation || 0}
				ref={ref}
				{...others}
				sx={{
					position: 'relative',
					border: border ? '1px solid' : 'none',
					borderRadius: 1,
					borderColor:
						theme.palette.mode === 'dark'
							? theme.palette.divider
							: theme.palette.grey.A800,
					boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit',
					':hover': {
						boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit',
					},
					...(modal && {
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: { xs: 'calc( 100% - 50px)', sm: 'auto' },
						'& .MuiCardContent-root': {
							overflowY: 'auto',
							minHeight: 'auto',
							maxHeight: 'calc(100vh - 200px)',
						},
					}),
					...sx,
				}}
			>
				{/* card header and action */}
				{!darkTitle && title && (
					<CardHeader
						sx={headerSX}
						titleTypographyProps={{
							variant: 'subtitle1',
							sx: {
								fontSize: '16px',
								fontWeight: 600,
								lineHeight: '24px',
							},
						}}
						title={title}
						action={secondary}
						subheader={subheader}
					/>
				)}
				{darkTitle && title && (
					<CardHeader
						sx={headerSX}
						title={
							<Typography
								variant="h4"
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									lineHeight: '24px',
								}}
							>
								{title}
							</Typography>
						}
						action={secondary}
					/>
				)}

				{/* content & header divider */}
				{title && divider && <Divider />}

				{/* card content */}
				{content && <CardContent sx={contentSX}>{children}</CardContent>}
				{!content && children}
			</Card>
		);
	},
);

MainCard.displayName = 'MainCard';

MainCard.defaultProps = {
	border: true,
	boxShadow: true,
	children: null,
	subheader: '',
	content: true,
	contentSX: {},
	darkTitle: false,
	divider: true,
	elevation: undefined,
	secondary: null,
	shadow: '',
	sx: {},
	title: null,
	modal: false,
};

MainCard.propTypes = {
	border: PropTypes.bool,
	boxShadow: PropTypes.bool,
	children: PropTypes.node,
	subheader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	content: PropTypes.bool,
	contentSX: PropTypes.instanceOf(Object),
	darkTitle: PropTypes.bool,
	divider: PropTypes.bool,
	elevation: PropTypes.number,
	secondary: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string,
		PropTypes.object,
	]),
	shadow: PropTypes.string,
	sx: PropTypes.instanceOf(Object),
	title: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string,
		PropTypes.object,
	]),
	modal: PropTypes.bool,
};

export default MainCard;
