import { useEffect, useRef, useState } from 'react';
import {
	AppBar,
	Box,
	ClickAwayListener,
	Paper,
	Popper,
	Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MoreOutlined } from '@ant-design/icons';
import IconButton from '../../../../@extended/IconButton';
import Transitions from '../../../../@extended/Transitions';
import { AddShop } from './AddShop';
import { ActiveShopDropdown } from './ActiveShop';

function MobileSection() {
	const theme = useTheme();

	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const iconBackColorOpen =
		theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
	const iconBackColor =
		theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

	return (
		<>
			<Box sx={{ flexShrink: 0, ml: 0.75 }}>
				<IconButton
					sx={{
						color: 'text.primary',
						bgcolor: open ? iconBackColorOpen : iconBackColor,
					}}
					ref={anchorRef}
					aria-controls={open ? 'menu-list-grow' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					color="secondary"
					variant="light"
				>
					<MoreOutlined />
				</IconButton>
			</Box>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				style={{
					width: '100%',
				}}
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 9],
							},
						},
					],
				}}
			>
				{({ TransitionProps }) => (
					<Transitions type="fade" in={open} {...TransitionProps}>
						<Paper sx={{ boxShadow: theme.customShadows.z1 }}>
							<ClickAwayListener onClickAway={handleClose}>
								<AppBar color="inherit">
									<Toolbar className="header-content--mobile">
										<AddShop />
										<ActiveShopDropdown />
									</Toolbar>
								</AppBar>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</>
	);
}

export default MobileSection;
