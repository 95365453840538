import { types } from 'mobx-state-tree';
import { OrderBy } from '../sharedModels';

const Subscription = types.model('Subscription', {
	id: types.maybeNull(types.identifierNumber),
	title: types.optional(types.string, ''),
	price: types.optional(types.number, 0),
	currency: types.optional(types.string, ''),
	description: types.optional(types.string, ''),
	name: types.optional(types.string, ''),
	type: types.optional(types.string, ''),
	maxBots: types.optional(types.number, 2),
	maxCustomers: types.optional(types.number, 200),
});

const UserSubscription = types.model('UserSubscription', {
	id: types.maybeNull(types.identifierNumber),
	status: types.optional(types.string, ''),
	endDate: types.maybeNull(types.string),
	orderId: types.maybeNull(types.number),
	paymentId: types.maybeNull(types.string),
	paymentService: types.maybeNull(types.string),
	limitViolationDate: types.maybeNull(types.string),
	subscription: types.optional(Subscription, { id: 0 }),
});

const UserSubscriptionsSearchQuery = types.model(
	'UserSubscriptionsSearchQuery',
	{
		search: types.optional(types.string, ''),
		page: types.optional(types.number, 1),
		limit: types.optional(types.number, 10),
		order: types.optional(OrderBy, {}),
		columnVisibility: types.optional(types.frozen(), {}),
		columnOrder: types.optional(types.array(types.string), []),
		columnDimensions: types.optional(types.frozen(), {}),
		pinnedColumns: types.optional(types.frozen(), {}),
	},
);

export { Subscription, UserSubscription, UserSubscriptionsSearchQuery };
