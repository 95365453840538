import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import {
	EditOutlined,
	LogoutOutlined,
	UserOutlined,
	ShoppingCartOutlined,
	SendOutlined,
} from '@ant-design/icons';

function ProfileTab({ handleLogout }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleListItemClick = (event, index, route) => {
		setSelectedIndex(index);
		navigate(route);
	};

	return (
		<List
			component="nav"
			sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}
		>
			<ListItemButton
				selected={selectedIndex === 0}
				onClick={(event) => handleListItemClick(event, 0, '/profile/personal')}
			>
				<ListItemIcon>
					<EditOutlined />
				</ListItemIcon>
				<ListItemText primary={t('header-profile-tab.edit-profile')} />
			</ListItemButton>
			<ListItemButton
				selected={selectedIndex === 1}
				onClick={(event) => handleListItemClick(event, 1, '/profile/password')}
			>
				<ListItemIcon>
					<UserOutlined />
				</ListItemIcon>
				<ListItemText primary={t('header-profile-tab.change-password')} />
			</ListItemButton>
			<ListItemButton
				component="a"
				href="https://t.me/teleshop_pro_bot?start=64858a2b651657b6380e1be8"
				target="_blank"
			>
				<ListItemIcon>
					<SendOutlined />
				</ListItemIcon>
				<ListItemText primary={t('auth-footer.support-service')} />
			</ListItemButton>
			<ListItemButton
				component="a"
				href="https://t.me/teleshop_pro_bot?start=656af44866aebd75520798c5"
				target="_blank"
			>
				<ListItemIcon>
					<ShoppingCartOutlined />
				</ListItemIcon>
				<ListItemText primary={t('subscriptions.order-button')} />
			</ListItemButton>
			<ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
				<ListItemIcon>
					<LogoutOutlined />
				</ListItemIcon>
				<ListItemText primary={t('header-profile-tab.logout')} />
			</ListItemButton>
		</List>
	);
}

ProfileTab.propTypes = {
	handleLogout: PropTypes.func.isRequired,
};

export default ProfileTab;
