import { flow, getEnv } from 'mobx-state-tree';

const ImagesStoreActions = (self) => ({
	deleteOne: flow(function* deleteOne(fileName, folder) {
		const { api } = getEnv(self);

		try {
			yield api.images.delete(fileName, folder);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	createImage: flow(function* createImage(file, createThumbnail = false) {
		const { api } = getEnv(self);

		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('createThumbnail', createThumbnail.toString());

			const { data } = yield api.images.createImage(formData);
			return data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error.message);
		}
	}),

	deleteImageById: flow(function* deleteImageById(imageId) {
		const { api } = getEnv(self);

		try {
			yield api.images.deleteImage(imageId);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
});

export default ImagesStoreActions;
