import { types } from 'mobx-state-tree';
import CustomersStoreActions from './actions';
import { Customer, CustomerSearchQuery } from './model';
import { Meta } from '../sharedModels';

const CustomersStore = types
	.model('CustomersStore', {
		items: types.optional(types.array(Customer), []),
		meta: types.optional(Meta, {}),
		searchQuery: types.optional(CustomerSearchQuery, {}),
		activeCustomer: types.optional(Customer, { id: 0 }),
		isLoaded: types.optional(types.boolean, false),
		isLoading: types.optional(types.boolean, false),
	})
	.actions(CustomersStoreActions);

export default CustomersStore;
