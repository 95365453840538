import { types } from 'mobx-state-tree';
import AuthStoreActions from './actions';
import { User } from './model';

const AuthStore = types
	.model('AuthStore', {
		isLoggedIn: types.optional(types.boolean, false),
		user: types.optional(User, { id: 0 }),
	})
	.actions(AuthStoreActions);

export default AuthStore;
