import { toJS } from 'mobx';
import { clone, flow, getEnv, getParent } from 'mobx-state-tree';

const ProductsStoreActions = (self) => ({
	getAllWithPagination: flow(function* getAllWithPagination() {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			self.isLoading = true;
			const {
				data: { items, meta },
			} = yield api.products.getAll(shopId, self.searchQuery);

			self.meta = {
				...meta,
				columnVisibility: toJS(self.searchQuery.columnVisibility),
				columnOrder: toJS(self.searchQuery.columnOrder),
				columnDimensions: toJS(self.searchQuery.columnDimensions),
				pinnedColumns: toJS(self.searchQuery.pinnedColumns),
			};
			self.items = items;
			self.isLoading = false;
			self.isLoaded = true;
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	getAutocompleteProducts: flow(function* getAutocompleteProducts(
		search,
		onlyLonely,
	) {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			const { autocompleteSearchQuery } = self;
			const { filters } = autocompleteSearchQuery;

			const {
				data: { items },
			} = yield api.products.getAll(shopId, {
				...autocompleteSearchQuery,
				filters: { ...filters, lonely: onlyLonely ? true : filters.lonely },
				search,
			});

			self.autocompleteProducts = items.map((item) => ({
				label: `${item.title}(${item.sku})`,
				value: item,
			}));
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	create: flow(function* create(payload) {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			yield api.products.create(shopId, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	copyOne: flow(function* copyOne(id, payload) {
		const { api } = getEnv(self);

		try {
			yield api.products.copy(id, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	update: flow(function* update(shopId, id, payload) {
		const { api } = getEnv(self);

		try {
			yield api.products.update(shopId, id, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	updateProductImages: flow(function* updateProductImages(productId, payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.products.updateImages(productId, payload);

			const updatedProductIndex = self.items.findIndex(
				(item) => item.id === productId,
			);

			if (updatedProductIndex !== -1) {
				self.items[updatedProductIndex].images = data.images;
			}

			return data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	updateProductAttributes: flow(function* updateProductAttributes(id, payload) {
		const { api } = getEnv(self);

		try {
			yield api.products.updateProductAttributes(id, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	deleteOne: flow(function* deleteOne(id) {
		const { api } = getEnv(self);

		try {
			yield api.products.delete(id);
			yield self.getAllWithPagination();
		} catch (error) {
			self.selectedRows.clear();
			self.toDeleteRows.clear();
			throw new Error(error.response?.data?.message || error);
		}
	}),

	deleteMultiple: flow(function* deleteMultiple(shopId, productIds) {
		const { api } = getEnv(self);

		try {
			yield api.products.deleteMultiple({ shopId, productIds });
			yield self.getAllWithPagination();
		} catch (error) {
			self.selectedRows.clear();
			self.toDeleteRows.clear();
			throw new Error(error.response?.data?.message || error);
		}
	}),

	deleteToDeleteRows: flow(function* deleteToDeleteRows(rows) {
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		if (rows.length > 1) {
			yield self.deleteMultiple(shopId, rows);
		} else {
			yield self.deleteOne(rows[0]);
		}
		self.selectedRows.clear();
		self.toDeleteRows.clear();
	}),

	importProducts: flow(function* importProducts(
		payload,
		shopId,
		fileType,
		importType,
	) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.products[
				importType === 'file' ? 'importProducts' : 'importProductsFromUrl'
			](payload, shopId, fileType);
			const newFile = yield self.exportProducts(shopId);

			return { data, newFile };
		} catch (error) {
			throw new Error(
				error.response?.data?.validationErrors ||
					error.response?.data?.message ||
					error,
			);
		}
	}),

	exportProducts: flow(function* exportProducts(shopId) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.products.exportProducts(shopId);

			return data;
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	setSearchQuery: (value) => {
		const query = { ...self.searchQuery, ...value };
		self.searchQuery = query;
		sessionStorage.setItem('productsSearchQuery', JSON.stringify(query));
	},

	setSelectedRows: (rows) => {
		self.selectedRows = rows;
	},

	setToDeleteRows: (rows) => {
		self.toDeleteRows = rows;
	},

	setModalState(action, productId = null) {
		if (action === null) {
			self.modalState = null;
		} else {
			const product = self.items.find((p) => p.id === productId);

			if (product) {
				self.modalState = {
					action,
					product: clone(product),
				};
			} else {
				self.modalState = {
					action,
					product: null,
				};
			}
		}
	},
});

export default ProductsStoreActions;
