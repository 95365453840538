import { types } from 'mobx-state-tree';
import { OrderBy } from '../sharedModels';

const Value = types.model('Value', {
	id: types.maybeNull(types.identifierNumber),
	value: types.maybeNull(types.string),
});

const Attribute = types.model('Attribute', {
	id: types.maybeNull(types.identifierNumber),
	name: types.maybeNull(types.string),
	type: types.maybeNull(types.string),
	isVariant: types.maybeNull(types.boolean),
	createdAt: types.maybeNull(types.string),
	values: types.maybeNull(types.array(Value)),
});

const ProductAttribute = types.model('ProductAttribute', {
	id: types.maybeNull(types.identifierNumber),
	productId: types.maybeNull(types.number),
	attribute: types.optional(Attribute, {}),
	value: types.maybeNull(Value),
});

const AttributeSearchQuery = types.model('AttributeSearchQuery', {
	search: types.optional(types.string, ''),
	page: types.optional(types.number, 1),
	limit: types.optional(types.number, 10),
	order: types.optional(OrderBy, {}),
	columnVisibility: types.optional(types.frozen(), {}),
	columnOrder: types.optional(types.array(types.string), []),
	columnDimensions: types.optional(types.frozen(), {}),
	pinnedColumns: types.optional(types.frozen(), {}),
});

const ModalState = types.model('ModalState', {
	action: types.enumeration('Action', ['create']),
});

export { Attribute, ProductAttribute, AttributeSearchQuery, ModalState };
