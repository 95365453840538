import { types } from 'mobx-state-tree';
import {
	Subscription,
	UserSubscription,
	UserSubscriptionsSearchQuery,
} from './model';
import { Meta } from '../sharedModels';
import UserSubscriptionsStoreActions from './actions';

const UserSubscriptionsStore = types
	.model('UserSubscriptionsStore', {
		subscriptions: types.optional(types.array(Subscription), []),
		items: types.optional(types.array(UserSubscription), []),
		meta: types.optional(Meta, {}),
		searchQuery: types.optional(UserSubscriptionsSearchQuery, {}),
		isLoaded: types.optional(types.boolean, false),
		isLoading: types.optional(types.boolean, false),
	})
	.actions(UserSubscriptionsStoreActions);

export default UserSubscriptionsStore;
