import { flow, getEnv } from 'mobx-state-tree';

const ImportStoreActions = (self) => ({
	setMeta: (value) => {
		self.metaByShop = value;
		localStorage.setItem('importMeta', JSON.stringify(value));
	},
	cancelImport: flow(function* cancelImport(shopId) {
		const { api } = getEnv(self);
		try {
			yield api.products.cancelImport(shopId);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
});

export default ImportStoreActions;
