import {
	AppstoreOutlined,
	BarsOutlined,
	HolderOutlined,
	CopyOutlined,
} from '@ant-design/icons';
import Translate from '../../../utils/i18n';

const catalog = {
	id: 'group-catalog',
	title: <Translate value="menu-items.catalog" />,
	type: 'group',
	children: [
		{
			id: 'products',
			title: <Translate value="menu-items.products" />,
			type: 'item',
			url: '/tables/products',
			icon: AppstoreOutlined,
			breadcrumbs: false,
		},
		{
			id: 'attributes',
			title: <Translate value="menu-items.attributes" />,
			type: 'item',
			url: '/tables/attributes',
			icon: HolderOutlined,
			breadcrumbs: false,
		},
		{
			id: 'attributes-templates',
			title: <Translate value="menu-items.attributes-templates" />,
			type: 'item',
			url: '/tables/attributes-templates',
			icon: CopyOutlined,
			breadcrumbs: false,
		},
		{
			id: 'categories',
			title: <Translate value="menu-items.categories" />,
			type: 'item',
			url: '/tables/categories',
			icon: BarsOutlined,
			breadcrumbs: false,
		},
	],
};

export default catalog;
