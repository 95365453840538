import { ShoppingCartOutlined, MailOutlined } from '@ant-design/icons';
import Translate from '../../../utils/i18n';

const sales = {
	id: 'group-sales',
	title: <Translate value="menu-items.sales" />,
	type: 'group',
	children: [
		{
			id: 'orders',
			title: <Translate value="menu-items.orders" />,
			type: 'item',
			url: '/tables/orders',
			icon: ShoppingCartOutlined,
			breadcrumbs: false,
		},
		{
			id: 'mailing',
			title: <Translate value="menu-items.mailing" />,
			type: 'item',
			url: '/mailing',
			icon: MailOutlined,
			breadcrumbs: false,
		},
	],
};

export default sales;
