import { types } from 'mobx-state-tree';
import { configure } from 'mobx';
import api from '../api';
import AppStore from './appStore/store';
import MenuStore from './menuStore/store';
import AuthStore from './authStore/store';
import SnackbarStore from './snackbarStore/store';
import UserStore from './userStore/store';
import ShopsStore from './shopsStore/store';
import CategoriesStore from './categoriesStore/store';
import ProductsStore from './productsStore/store';
import OrdersStore from './ordersStore/store';
import UserSubscriptionsStore from './userSubscriptionsStore/store';
import ImagesStore from './imagesStore/store';
import MailingStore from './mailingStore/store';
import CustomersStore from './customersStore/store';
import AttributesStore from './attributesStore/store';
import AttributesTemplatesStore from './attributesTemplatesStore/store';
import NotificationsStore from './notificationsStore/store';
import ImportStore from './importStore/store';

configure({});

const RootStore = types.model('RootStore', {
	appStore: types.optional(AppStore, () => AppStore.create({})),
	menuStore: types.optional(MenuStore, () => MenuStore.create({})),
	authStore: types.optional(AuthStore, () => AuthStore.create({})),
	snackbarStore: types.optional(SnackbarStore, () => SnackbarStore.create({})),
	userStore: types.optional(UserStore, () => UserStore.create({})),
	shopsStore: types.optional(ShopsStore, () => ShopsStore.create({})),
	ordersStore: types.optional(OrdersStore, () => OrdersStore.create({})),
	notificationsStore: types.optional(NotificationsStore, () =>
		NotificationsStore.create({}),
	),
	importStore: types.optional(ImportStore, () => ImportStore.create({})),
	customersStore: types.optional(CustomersStore, () =>
		CustomersStore.create({}),
	),
	productsStore: types.optional(ProductsStore, () => ProductsStore.create({})),
	categoriesStore: types.optional(CategoriesStore, () =>
		CategoriesStore.create({}),
	),
	attributesStore: types.optional(AttributesStore, () =>
		AttributesStore.create({}),
	),
	attributesTemplatesStore: types.optional(AttributesTemplatesStore, () =>
		AttributesTemplatesStore.create({}),
	),
	userSubscriptionsStore: types.optional(UserSubscriptionsStore, () =>
		UserSubscriptionsStore.create({}),
	),
	imagesStore: types.optional(ImagesStore, () => ImagesStore.create({})),
	mailingStore: types.optional(MailingStore, () => MailingStore.create({})),
});

const RootStoreInstance = RootStore.create({}, { api });

export default RootStoreInstance;
