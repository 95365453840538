import { flow, getEnv, getParent } from 'mobx-state-tree';

const MailingStoreActions = (self) => ({
	create: flow(function* create(payload) {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		payload.append('shopId', shopId);

		try {
			yield api.mailing.create(payload);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
});

export default MailingStoreActions;
