import SimpleBar from '../../../../common/SimpleBar';
import { Navigation } from './Navigation';

export function DrawerContent() {
	return (
		<SimpleBar
			sx={{
				'& .simplebar-content': {
					display: 'flex',
					flexDirection: 'column',
				},
			}}
		>
			<Navigation />
		</SimpleBar>
	);
}
