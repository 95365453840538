/* eslint-disable react/jsx-no-useless-fragment */
import { useRef, useState, useMemo, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
	Avatar,
	Badge,
	Box,
	ClickAwayListener,
	Divider,
	List,
	ListItemButton,
	ListItemAvatar,
	ListItemText,
	Paper,
	Popper,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';
import {
	BellOutlined,
	CheckCircleOutlined,
	ImportOutlined,
} from '@ant-design/icons';
import { formatDistanceToNow } from 'date-fns';
import * as locale from 'date-fns/locale';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../../../@extended/IconButton';
import Transitions from '../../../../../@extended/Transitions';
import MainCard from '../../../../../common/MainCard';
import { useStores } from '../../../../../../stores/context';

const avatarSX = {
	width: 36,
	height: 36,
	fontSize: '1rem',
};

const actionSX = {
	mt: '6px',
	ml: 1,
	top: 'auto',
	right: 'auto',
	alignSelf: 'flex-start',
	transform: 'none',
};

const notificationIcons = {
	import: <ImportOutlined />,
};

export const Notification = observer(() => {
	const theme = useTheme();
	const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
	const { t, i18n } = useTranslation();

	const {
		notificationsStore: { notifications, setNotifications },
		authStore: {
			user: { id },
		},
	} = useStores();

	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [viewAll, setViewAll] = useState(false);

	const unread = useMemo(
		() => notifications.filter((notification) => !notification.read),
		[toJS(notifications)],
	);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleReadAll = useCallback(() => {
		const updatedNotifications = notifications.map((notification) => {
			if (!notification.read) return { ...notification, read: true };
			return notification;
		});
		const allNotifications =
			JSON.parse(localStorage.getItem('teleshopNotifications')) || {};
		allNotifications[id] = updatedNotifications;
		localStorage.setItem(
			'teleshopNotifications',
			JSON.stringify(allNotifications),
		);
		setNotifications(updatedNotifications);
	}, [id]);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const iconBackColorOpen =
		theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
	const iconBackColor =
		theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

	return (
		<Box sx={{ flexShrink: 0, ml: 0.75 }}>
			<IconButton
				color="secondary"
				variant="light"
				sx={{
					color: 'text.primary',
					bgcolor: open ? iconBackColorOpen : iconBackColor,
				}}
				aria-label="open profile"
				ref={anchorRef}
				aria-controls={open ? 'profile-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
			>
				<Badge badgeContent={unread.length} color="primary">
					<BellOutlined />
				</Badge>
			</IconButton>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [matchesXs ? 0 : 0, 9],
							},
						},
					],
				}}
			>
				{({ TransitionProps }) => (
					<Transitions type="fade" in={open} {...TransitionProps}>
						<Paper
							sx={{
								boxShadow: theme.customShadows.z1,
								width: '100%',
								minWidth: 285,
								maxWidth: 420,
								[theme.breakpoints.down('md')]: {
									maxWidth: 285,
								},
							}}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<MainCard
									title={t('notifications.title')}
									elevation={0}
									border={false}
									content={false}
									secondary={
										<>
											{unread.length > 0 && (
												<Tooltip
													title={t('notifications.read-all')}
													componentsProps={{
														tooltip: {
															sx: {
																bgcolor: '#1e1e1e',
															},
														},
													}}
												>
													<IconButton
														color="success"
														size="small"
														onClick={handleReadAll}
													>
														<CheckCircleOutlined
															style={{ fontSize: '1.15rem' }}
														/>
													</IconButton>
												</Tooltip>
											)}
										</>
									}
								>
									<List
										component="nav"
										sx={{
											p: 0,
											'& .MuiListItemButton-root': {
												py: 0.5,
												'&.Mui-selected': {
													bgcolor: 'grey.50',
													color: 'text.primary',
												},
												'& .MuiAvatar-root': avatarSX,
												'& .MuiListItemSecondaryAction-root': {
													...actionSX,
													position: 'relative',
												},
											},
											overflow: 'auto',
											maxHeight: 500,
										}}
									>
										{(viewAll ? notifications : notifications.slice(0, 5)).map(
											(notification) => (
												<div key={notification.date}>
													<ListItemButton selected={!notification.read}>
														<ListItemAvatar>
															<Avatar
																sx={{
																	color: `${notification.type}.main`,
																	bgcolor: `${notification.type}.lighter`,
																}}
															>
																{notificationIcons[notification.from]}
															</Avatar>
														</ListItemAvatar>
														<ListItemText
															primary={
																<Typography variant="h6">
																	{t(`notifications.${notification.message}`)}
																</Typography>
															}
															secondary={formatDistanceToNow(
																new Date(notification.date),
																{
																	addSuffix: true,
																	locale: locale[i18n.resolvedLanguage],
																},
															)}
														/>
													</ListItemButton>
													<Divider />
												</div>
											),
										)}
										<ListItemButton
											sx={{ textAlign: 'center', py: '12px !important' }}
											onClick={() => setViewAll(!viewAll)}
										>
											<ListItemText
												primary={
													<Typography variant="h6" color="primary">
														{t(
															`notifications.${
																viewAll ? 'view-less' : 'view-all'
															}`,
														)}
													</Typography>
												}
											/>
										</ListItemButton>
									</List>
								</MainCard>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</Box>
	);
});
