import { Alert, Button, Fade, Grow, Slide } from '@mui/material';
import { observer } from 'mobx-react';
import MuiSnackbar from '@mui/material/Snackbar';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/context';
import IconButton from './IconButton';

function TransitionSlideLeft(props) {
	return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
	return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
	return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
	return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
	return <Grow {...props} />;
}

const animation = {
	SlideLeft: TransitionSlideLeft,
	SlideUp: TransitionSlideUp,
	SlideRight: TransitionSlideRight,
	SlideDown: TransitionSlideDown,
	Grow: GrowTransition,
	Fade,
};

export const Snackbar = observer(() => {
	const { t } = useTranslation();
	const {
		snackbarStore: {
			closeSnackbar,
			actionButton,
			anchorOrigin,
			alert,
			close,
			message,
			open,
			transition,
			variant,
		},
	} = useStores();

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		closeSnackbar();
	};
	return (
		<>
			{variant === 'default' && (
				<MuiSnackbar
					anchorOrigin={anchorOrigin}
					open={open}
					sx={{ marginTop: '64px' }}
					autoHideDuration={3000}
					onClose={handleClose}
					message={message}
					TransitionComponent={animation[transition]}
					action={
						<>
							<Button color="secondary" size="small" onClick={handleClose}>
								{t('snackbar.undo-button')}
							</Button>
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={handleClose}
								sx={{ mt: 0.25 }}
							>
								<CloseOutlined />
							</IconButton>
						</>
					}
				/>
			)}
			{variant === 'alert' && (
				<MuiSnackbar
					TransitionComponent={animation[transition]}
					anchorOrigin={anchorOrigin}
					open={open}
					sx={{ marginTop: '64px' }}
					autoHideDuration={3000}
					onClose={handleClose}
				>
					<Alert
						variant={alert.variant}
						color={alert.color}
						action={
							<>
								{actionButton !== false && (
									<Button
										color={alert.color}
										size="small"
										onClick={handleClose}
									>
										{t('snackbar.undo-button')}
									</Button>
								)}
								{close !== false && (
									<IconButton
										sx={{ mt: 0.25 }}
										size="small"
										aria-label="close"
										variant="contained"
										color={alert.color}
										onClick={handleClose}
									>
										<CloseOutlined />
									</IconButton>
								)}
							</>
						}
						sx={{
							...(alert.variant === 'outlined' && {
								bgcolor: 'grey.0',
							}),
						}}
					>
						{message}
					</Alert>
				</MuiSnackbar>
			)}
		</>
	);
});
