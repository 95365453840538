import { flow, getEnv, getParent } from 'mobx-state-tree';
import jwtDecode from 'jwt-decode';

const UserStoreActions = (self) => ({
	update: flow(function* update(payload) {
		const { api } = getEnv(self);
		const { authStore } = getParent(self);

		try {
			const { data } = yield api.users.update(payload);

			authStore.setUser({ ...authStore.user, ...jwtDecode(data) });
			localStorage.setItem('accessToken', data);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	updateActiveShopId: flow(function* updateActiveShopId(payload) {
		const { api } = getEnv(self);

		try {
			yield api.users.updateActiveShopId(payload);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	getStatistic: flow(function* getStatistic() {
		const { api } = getEnv(self);
		const { authStore } = getParent(self);

		try {
			const { data: statistic } = yield api.users.statistic();

			authStore.setUser({ ...authStore.user, statistic });
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
});

export default UserStoreActions;
