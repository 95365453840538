import { types } from 'mobx-state-tree';

const Notification = types.model('Notification', {
	message: types.optional(types.string, ''),
	from: types.optional(types.string, ''),
	date: types.optional(types.string, ''),
	type: types.optional(types.string, ''),
	read: types.optional(types.boolean, false),
});

export { Notification };
