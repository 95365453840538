import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/context';
import menuItem, { menuItemsNoShops } from '../../../../../common/MenuItems';
import { NavGroup } from './NavGroup';

export const Navigation = observer(() => {
	const {
		shopsStore: { activeShop },
		menuStore: { isDrawerOpen },
	} = useStores();

	const menu = activeShop.id ? menuItem : menuItemsNoShops;

	const navGroups = menu.items.map((item) => {
		switch (item.type) {
			case 'group':
				return <NavGroup key={item.id} item={item} />;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Fix - Navigation Group
					</Typography>
				);
		}
	});

	return (
		<Box
			sx={{
				pt: isDrawerOpen ? 2 : 0,
				mb: isDrawerOpen ? '32px' : 0,
				'& > ul:first-of-type': { mt: 0 },
			}}
		>
			{navGroups}
		</Box>
	);
});
