import { types } from 'mobx-state-tree';

const Meta = types.model('Meta', {
	currentPage: types.optional(types.number, 1),
	itemCount: types.optional(types.number, 0),
	itemsPerPage: types.optional(types.number, 10),
	totalItems: types.optional(types.number, 0),
	totalPages: types.optional(types.number, 1),
	columnVisibility: types.optional(types.frozen(), {}),
	columnOrder: types.optional(types.array(types.string), []),
	columnDimensions: types.optional(types.frozen(), {}),
	pinnedColumns: types.optional(types.frozen(), {}),
});

const OrderBy = types.model('OrderBy', {
	field: types.optional(types.string, 'id'),
	type: types.optional(types.string, 'DESC'),
});

const ImageModel = types.model('Image', {
	id: types.identifierNumber,
	originalUrl: types.maybeNull(types.string),
	regularUrl: types.maybeNull(types.string),
	thumbnailUrl: types.maybeNull(types.string),
	createdAt: types.string,
	updatedAt: types.string,
});

export { Meta, OrderBy, ImageModel };
