import { types } from 'mobx-state-tree';
import SnackbarActions from './actions';

const AnchorOrigin = types.model('AnchorOrigin', {
	vertical: types.optional(types.string, 'top'),
	horizontal: types.optional(types.string, 'right'),
});

const Alert = types.model('Alert', {
	color: types.optional(types.string, 'primary'),
	variant: types.optional(types.string, 'filled'),
});

const SnackbarStore = types
	.model('SnackbarStore', {
		action: types.optional(types.boolean, false),
		open: types.optional(types.boolean, false),
		message: types.optional(types.string, 'Note archived'),
		anchorOrigin: types.optional(AnchorOrigin, {
			vertical: 'top',
			horizontal: 'right',
		}),
		variant: types.optional(types.string, 'default'),
		alert: types.optional(Alert, {
			color: 'primary',
			variant: 'filled',
		}),
		transition: types.optional(types.string, 'Fade'),
		close: types.optional(types.boolean, true),
		actionButton: types.optional(types.boolean, false),
	})
	.actions(SnackbarActions);

export default SnackbarStore;
