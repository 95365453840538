import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { LanguageSelector } from '../../../common/LanguageSelector';
import DrawerHeader from './DrawerHeader';
import { DrawerContent } from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { ThemeSelector } from '../../../common/ThemeSelector';

function MainDrawer({ open, handleDrawerToggle, window }) {
	const theme = useTheme();
	const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

	// responsive drawer container
	const container = window !== null ? () => window().document.body : null;

	// header content
	const drawerContent = useMemo(() => <DrawerContent />, []);
	const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

	return (
		<Box
			component="nav"
			sx={{ flexShrink: { md: 0 }, zIndex: 1200 }}
			aria-label="mailbox folders"
		>
			{!matchDownSM ? (
				<MiniDrawerStyled variant="permanent" open={open}>
					{drawerHeader}
					{drawerContent}
					{open ? <ThemeSelector /> : null}
					{open ? <LanguageSelector /> : null}
				</MiniDrawerStyled>
			) : (
				<Drawer
					container={container}
					variant="temporary"
					open={open}
					onClose={handleDrawerToggle}
					ModalProps={{ keepMounted: true }}
					sx={{
						display: { xs: 'block', lg: 'none' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: 260,
							borderRight: `1px solid ${theme.palette.divider}`,
							backgroundImage: 'none',
							boxShadow: 'inherit',
						},
					}}
				>
					{drawerHeader}
					{drawerContent}
					<ThemeSelector />
					<LanguageSelector />
				</Drawer>
			)}
		</Box>
	);
}

MainDrawer.defaultProps = {
	window: null,
};

MainDrawer.propTypes = {
	open: PropTypes.bool.isRequired,
	window: PropTypes.instanceOf(Object),
	handleDrawerToggle: PropTypes.func.isRequired,
};

export default MainDrawer;
