import { clone, flow, getEnv, getParent } from 'mobx-state-tree';
import { set, toJS } from 'mobx';

const AttributesTemplatesStoreActions = (self) => ({
	createOne: flow(function* createOne(payload) {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			yield api.attributesTemplates.create(shopId, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error?.response?.status || error);
		}
	}),

	updateOne: flow(function* updateOne(id, payload) {
		const { api } = getEnv(self);

		try {
			yield api.attributesTemplates.update(id, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error?.response?.status || error);
		}
	}),

	deleteOne: flow(function* deleteOne(id) {
		const { api } = getEnv(self);

		try {
			yield api.attributesTemplates.delete(id);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	deleteToDeleteRows: flow(function* deleteToDeleteRows(rows) {
		yield self.deleteOne(rows[0]);
		self.toDeleteRows.clear();
	}),

	getAll: flow(function* getAll() {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			const { data } = yield api.attributesTemplates.getAll(shopId);
			self.items = data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	getAllWithPagination: flow(function* getAllWithPagination() {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			self.isLoading = true;
			const {
				data: { items, meta },
			} = yield api.attributesTemplates.getAllWithPagination(
				shopId,
				self.searchQuery,
			);

			self.meta = {
				...meta,
				columnVisibility: toJS(self.searchQuery.columnVisibility),
				columnOrder: toJS(self.searchQuery.columnOrder),
				columnDimensions: toJS(self.searchQuery.columnDimensions),
				pinnedColumns: toJS(self.searchQuery.pinnedColumns),
			};
			self.items = items;
			self.isLoading = false;
			self.isLoaded = true;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	setSearchQuery: (value) => {
		set(self.searchQuery, value);
		sessionStorage.setItem(
			'attributesTemplatesSearchQuery',
			JSON.stringify(self.searchQuery),
		);
	},

	setToDeleteRows: (rows) => {
		self.toDeleteRows = rows;
	},

	setModalState(action, templateId = null) {
		if (action === null) {
			self.modalState = null;
		} else {
			const template = self.items.find((t) => t.id === templateId);

			if (template) {
				self.modalState = {
					action,
					template: clone(template),
				};
			} else {
				self.modalState = {
					action,
					template: null,
				};
			}
		}
	},
});

export default AttributesTemplatesStoreActions;
