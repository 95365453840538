import { lazy } from 'react';
import Loadable from '../components/common/Loadable';
import GuestGuard from '../utils/route-guard/GuestGuard';
import CommonLayout from '../components/layouts/CommonLayout';

const AuthRegister = Loadable(
	lazy(() => import('../screens/auth/RegisterPage')),
);
const AuthLogin = Loadable(lazy(() => import('../screens/auth/LoginPage')));
const AuthForgotPassword = Loadable(
	lazy(() => import('../screens/auth/ForgotPasswordPage')),
);
const AuthCheckMail = Loadable(
	lazy(() => import('../screens/auth/CheckMailPage')),
);
const AuthResetPassword = Loadable(
	lazy(() => import('../screens/auth/ResetPasswordPage')),
);
const AuthCodeVerification = Loadable(
	lazy(() => import('../screens/auth/CodeVerificationPage')),
);

const LoginRoutes = {
	path: '/',
	children: [
		{
			path: '/',
			element: (
				<GuestGuard>
					<CommonLayout />
				</GuestGuard>
			),
			children: [
				{
					path: '/',
					element: <AuthLogin />,
				},
				{
					path: 'login',
					element: <AuthLogin />,
				},
				{
					path: 'register',
					element: <AuthRegister />,
				},
				{
					path: 'forgot-password',
					element: <AuthForgotPassword />,
				},
				{
					path: 'check-mail',
					element: <AuthCheckMail />,
				},
				{
					path: 'reset-password',
					element: <AuthResetPassword />,
				},
				{
					path: 'code-verification',
					element: <AuthCodeVerification />,
				},
			],
		},
	],
};

export default LoginRoutes;
