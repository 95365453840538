import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

function SendPulseChat() {
	useEffect(() => {
		const chatUrl = process.env.REACT_APP_SENDPULSE_CHAT_URL;
		const chatId = process.env.REACT_APP_SENDPULSE_CHAT_ID;

		try {
			if (!window.sendPulseChatInitialized && chatUrl && chatId) {
				const script = document.createElement('script');
				script.src = chatUrl;
				script.setAttribute('data-live-chat-id', chatId);
				script.async = true;

				script.onerror = (error) => {
					Sentry.captureException(error);
				};

				document.body.appendChild(script);
				window.sendPulseChatInitialized = true;
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	}, []);

	return null;
}

export default SendPulseChat;
