import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

const openedMixin = (theme) => ({
	width: 260,
	borderRight: `1px solid ${theme.palette.divider}`,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	boxShadow: theme.palette.mode === 'dark' ? theme.customShadows.z1 : 'none',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: theme.spacing(7.5),
	borderRight: 'none',
	boxShadow: theme.customShadows.z1,
});

const MiniDrawerStyled = styled(Drawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: 260,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export default MiniDrawerStyled;
