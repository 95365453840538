import React from 'react';
import ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import App from './App';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: ['localhost', process.env.REACT_APP_BASE_URL],
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
});
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	process.env.REACT_APP_ENV === 'dev' ? (
		<React.StrictMode>
			<App />
		</React.StrictMode>
	) : (
		<App />
	),
);
