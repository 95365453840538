import { flow, getEnv } from 'mobx-state-tree';
import jwtDecode from 'jwt-decode';

const MenuStoreActions = (self) => ({
	register: flow(function* register(payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.auth.register(payload);
			self.user = jwtDecode(data.accessToken);
			localStorage.setItem('accessToken', data.accessToken);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
	generateApiKey: flow(function* generateApiKey(payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.auth.generateApiKey(payload);

			return data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
	generateTelegramAuthLink: flow(function* generateTelegramAuthLink(payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.auth.generateTelegramAuthLink(payload);

			return data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
	checkTelegramAuthorization: flow(function* checkTelegramAuthorization(
		payload,
	) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.auth.checkTelegramAuthorization(payload);
			self.user = jwtDecode(data.accessToken);
			localStorage.setItem('accessToken', data.accessToken);
			self.isLoggedIn = true;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
	loginFromCheckEmailForm: () => {
		const token = window.localStorage.getItem('accessToken');

		if (!token) {
			return;
		}

		self.isLoggedIn = true;
	},
	login: flow(function* login(payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.auth.login(payload);
			self.user = jwtDecode(data.accessToken);
			localStorage.setItem('accessToken', data.accessToken);
			self.isLoggedIn = true;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
	logout: flow(function* logout() {
		const { api } = getEnv(self);

		try {
			self.isLoggedIn = false;
			yield api.auth.removeHashedRefreshToken();
			localStorage.removeItem('accessToken');
			sessionStorage.clear();
			yield api.auth.logout();
			window.location.reload();
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
	loginWithMagicLink: (token) => {
		try {
			self.user = jwtDecode(token);
			localStorage.setItem('accessToken', token);
			self.isLoggedIn = true;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	},
	restore: flow(function* restore() {
		const { api } = getEnv(self);
		const token = window.localStorage.getItem('accessToken');

		if (!token) {
			self.user = { id: 0 };
			return;
		}

		self.user = jwtDecode(token);
		self.isLoggedIn = true;

		try {
			const { data: user } = yield api.auth.getMe();
			self.user = user;
			self.isLoggedIn = true;
		} catch (error) {
			self.user = { id: 0 };
			localStorage.setItem('accessToken', '');
			self.isLoggedIn = false;
		}
	}),
	verifyEmail: flow(function* verifyEmail() {
		const { api } = getEnv(self);

		try {
			yield api.auth.verifyEmail();
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
	forgotPassword: flow(function* login(payload) {
		const { api } = getEnv(self);

		try {
			yield api.auth.forgotPassword(payload);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
	changePassword: flow(function* changePassword(payload) {
		const { api } = getEnv(self);

		try {
			yield api.auth.changePassword(payload);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
	setUser: (value) => {
		self.user = value;
	},
});

export default MenuStoreActions;
