import { types } from 'mobx-state-tree';
import AttributesTemplatesStoreActions from './actions';
import {
	AttributesTemplate,
	AttributesTemplatesSearchQuery,
	ModalState,
} from './model';
import { Meta } from '../sharedModels';

const AttributesTemplatesStore = types
	.model('AttributesTemplatesStore', {
		items: types.optional(types.array(AttributesTemplate), []),
		meta: types.optional(Meta, {}),
		modalState: types.optional(types.maybeNull(ModalState), null),
		searchQuery: types.optional(AttributesTemplatesSearchQuery, {}),
		toDeleteRows: types.optional(types.array(types.number), []),
		isLoaded: types.optional(types.boolean, false),
		isLoading: types.optional(types.boolean, false),
	})
	.actions(AttributesTemplatesStoreActions);

export default AttributesTemplatesStore;
