const AppStoreActions = (self) => ({
	setIsOverlayVisible: (value) => {
		self.isOverlayVisible = value;
	},
	toggleLoading: (value) => {
		self.loading = value;
	},
	setThemeMode: (value) => {
		self.themeMode = value;
		localStorage.setItem('themeMode', value);
	},
	getThemeMode: () => {
		const value = window.localStorage.getItem('themeMode');
		if (value) self.themeMode = value;
		else localStorage.setItem('themeMode', 'dark');
	},
});

export default AppStoreActions;
