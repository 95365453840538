import { toJS } from 'mobx';
import { flow, getEnv } from 'mobx-state-tree';

const ShopsStoreActions = (self) => ({
	findAllByUserId: flow(function* findAllByUserId() {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.shops.getAllByUserId();
			self.shops = data;
			self.isLoaded = true;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message ||
					(typeof error === 'string' ? error : error.message),
			);
		}
	}),

	setActiveShop: (shop) => {
		self.activeShop = shop;
	},

	create: flow(function* create(payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.shops.create(payload);
			self.shops = [...self.shops, data];
			self.activeShop = data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	deleteOne: flow(function* deleteOne(id) {
		const { api } = getEnv(self);

		try {
			yield api.shops.delete(id);
			const shops = toJS(self.shops).filter((shop) => shop.id !== id);
			const activeShop = shops.length > 0 ? shops[0] : { id: 0 };
			self.activeShop = activeShop;
			self.shops = shops;
			return activeShop.id;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	update: flow(function* update(shopId, payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.shops.update(shopId, payload);
			const index = self.shops.findIndex((shop) => shop.id === data.id);
			self.shops[index] = data;
			self.activeShop = data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	createShopParameters: flow(function* createShopParameters(payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.shopParameters.create(payload);

			self.activeShop.parameters = data.parameters;
			self.findAllByUserId();
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	createShopLocations: flow(function* createShopLocations(payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.shopLocations.create(payload);

			self.shops = self.shops.map((shop) =>
				shop.id === self.activeShop.id
					? { ...shop, shopLocations: data }
					: shop,
			);

			self.activeShop.shopLocations = data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	updateShopImages: flow(function* updateShopImages(shopId, payload) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.shops.updateImages(shopId, payload);

			self.shops = self.shops.map((shop) =>
				shop.id === shopId ? { ...shop, images: data.images } : shop,
			);

			if (self.activeShop.id === shopId) {
				self.activeShop.images = data.images;
			}

			return data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	updateShopAvatar: flow(function* updateShopAvatar(shopId, avatarId) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.shops.updateAvatar(shopId, { avatarId });

			self.activeShop.avatar = data;

			self.shops = self.shops.map((shop) =>
				shop.id === self.activeShop.id
					? {
							...shop,
							avatar: data,
					  }
					: shop,
			);
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	updateShopLocationImages: flow(function* updateShopLocationImages(
		locationId,
		payload,
	) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.shopLocations.updateImages(
				locationId,
				payload,
			);

			self.activeShop.shopLocations = self.activeShop.shopLocations.map(
				(location) =>
					location.id === locationId
						? { ...location, images: data.images }
						: location,
			);

			self.shops = self.shops.map((shop) =>
				shop.id === self.activeShop.id
					? {
							...shop,
							shopLocations: shop.shopLocations.map((location) =>
								location.id === locationId
									? { ...location, images: data.images }
									: location,
							),
					  }
					: shop,
			);

			return data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	deleteShopLocation: flow(function* deleteShopLocation(locationId) {
		const { api } = getEnv(self);

		try {
			yield api.shopLocations.delete(locationId);

			const filteredData = self.activeShop.shopLocations
				.map((location) => toJS(location))
				.filter((location) => location.id !== locationId);

			self.shops = self.shops.map((shop) =>
				shop.id === self.activeShop.id
					? { ...shop, shopLocations: filteredData }
					: shop,
			);

			self.activeShop.shopLocations = filteredData;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	getPaymentMethods: flow(function* getPaymentMethods() {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.paymentMethods.getAll();
			self.paymentMethods = data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	createShopOnlinePaymentMethods: flow(function* createShopOnlinePaymentMethods(
		payload,
	) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.shopOnlinePaymentMethods.create(payload);

			self.shops = self.shops.map((shop) =>
				shop.id === self.activeShop.id
					? { ...shop, onlinePaymentMethods: data }
					: shop,
			);

			self.activeShop.onlinePaymentMethods = data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	deleteShopOnlinePaymentMethod: flow(function* deleteShopOnlinePaymentMethod(
		paymentMethodId,
	) {
		const { api } = getEnv(self);

		try {
			yield api.shopOnlinePaymentMethods.delete(paymentMethodId);

			const filteredData = self.activeShop.onlinePaymentMethods
				.map((method) => toJS(method))
				.filter((method) => method.id !== paymentMethodId);

			self.shops = self.shops.map((shop) =>
				shop.id === self.activeShop.id
					? { ...shop, onlinePaymentMethods: filteredData }
					: shop,
			);

			self.activeShop.onlinePaymentMethods = filteredData;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),
});

export default ShopsStoreActions;
