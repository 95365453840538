import PropTypes from 'prop-types';
import { Box, List, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { NavItem } from './NavItem';
import { NavCollapse } from './NavCollapse';
import { useStores } from '../../../../../../stores/context';

export const NavGroup = observer(({ item }) => {
	const {
		menuStore: { isDrawerOpen },
	} = useStores();

	const theme = useTheme();

	const navCollapse = item.children?.map((menuItem) => {
		switch (menuItem.type) {
			case 'collapse':
				return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
			case 'item':
				return <NavItem key={menuItem.id} item={menuItem} level={1} />;
			default:
				return (
					<Typography
						key={menuItem.id}
						variant="h6"
						color="error"
						align="center"
					>
						Fix - Group Collapse or Items
					</Typography>
				);
		}
	});

	return (
		<List
			subheader={
				item.title &&
				isDrawerOpen && (
					<Box sx={{ pl: 3, mb: 1.5 }}>
						<Typography
							variant="subtitle2"
							color={
								theme.palette.mode === 'dark'
									? 'textSecondary'
									: 'text.secondary'
							}
						>
							{item.title}
						</Typography>
						{item.caption && (
							<Typography variant="caption" color="secondary">
								{item.caption}
							</Typography>
						)}
					</Box>
				)
			}
			sx={{ mt: isDrawerOpen && item.title ? 1.5 : 0, py: 0, zIndex: 0 }}
		>
			{navCollapse}
		</List>
	);
});

NavGroup.propTypes = {
	item: PropTypes.instanceOf(Object).isRequired,
};
