import { lazy } from 'react';
import Loadable from '../components/common/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
import { MainLayout } from '../components/layouts/MainLayout';

const ImportExportExcelPage = Loadable(
	lazy(() => import('../screens/ImportExportExcelPage')),
);

const ShopPage = Loadable(lazy(() => import('../screens/ShopPage')));
const MailingPage = Loadable(lazy(() => import('../screens/MailingPage')));
const ShopLocationsTab = Loadable(
	lazy(() => import('../screens/ShopPage/components/ShopLocationsTab')),
);
const ShopDeliveryMethodsTab = Loadable(
	lazy(() => import('../screens/ShopPage/components/ShopDeliveryMethodsTab')),
);
const ShopPaymentMethodsTab = Loadable(
	lazy(() => import('../screens/ShopPage/components/ShopPaymentMethodsTab')),
);
const ShopParametersTab = Loadable(
	lazy(() => import('../screens/ShopPage/components/ShopParametersTab')),
);

const OrdersTable = Loadable(lazy(() => import('../components/tables/Orders')));
const ProductsTable = Loadable(
	lazy(() => import('../components/tables/Products')),
);
const CategoriesTable = Loadable(
	lazy(() => import('../components/tables/Categories')),
);
const AttributesTable = Loadable(
	lazy(() => import('../components/tables/Attributes')),
);
const AttributesTemplatesTable = Loadable(
	lazy(() => import('../components/tables/AttributesTemplates')),
);
const CustomersTable = Loadable(
	lazy(() => import('../components/tables/Customers')),
);

const UserPage = Loadable(lazy(() => import('../screens/UserPage')));
const UserTabPayment = Loadable(
	lazy(() => import('../screens/UserPage/components/TabSubscriptions')),
);
const UserTabPassword = Loadable(
	lazy(() => import('../screens/UserPage/components/TabPassword')),
);
const UserTabPersonal = Loadable(
	lazy(() => import('../screens/UserPage/components/TabPersonal')),
);

const ApiPage = Loadable(lazy(() => import('../screens/ApiPage')));
const ApiTabToken = Loadable(
	lazy(() => import('../screens/ApiPage/components/TabToken')),
);

const CustomerPage = Loadable(lazy(() => import('../screens/CustomerPage')));
const CustomerTabPersonal = Loadable(
	lazy(() => import('../screens/CustomerPage/components/TabPersonal')),
);
const CustomerTabOrders = Loadable(
	lazy(() => import('../screens/CustomerPage/components/TabOrders')),
);
const CustomerTabContactDataHistory = Loadable(
	lazy(() =>
		import('../screens/CustomerPage/components/TabContactDataHistory'),
	),
);

// const Subscriptions = Loadable(lazy(() => import('../screens/Subscriptions')));
// const Plans = Loadable(
// 	lazy(() => import('../screens/Subscriptions/components/Plans')),
// );

const MainRoutes = {
	path: '/',
	children: [
		{
			path: '/',
			element: (
				<AuthGuard>
					<MainLayout />
				</AuthGuard>
			),
			children: [
				{
					path: 'import-export',
					element: <ImportExportExcelPage />,
				},
				{
					path: 'mailing',
					element: <MailingPage />,
				},
				{
					path: 'api',
					element: <ApiPage />,
					children: [
						{
							path: 'token',
							element: <ApiTabToken />,
						},
					],
				},
				{
					path: 'shops',
					children: [
						{
							path: 'edit',
							element: <ShopPage />,
						},
						{
							path: 'add',
							element: <ShopPage isCreate />,
						},
						{
							path: 'locations',
							element: <ShopLocationsTab />,
						},
						{
							path: 'delivery-methods',
							element: <ShopDeliveryMethodsTab />,
						},
						{
							path: 'payment-methods',
							element: <ShopPaymentMethodsTab />,
						},
						{
							path: 'parameters',
							element: <ShopParametersTab />,
						},
					],
				},
				{
					path: 'tables',
					children: [
						{
							path: 'products',
							element: <ProductsTable />,
						},
						{
							path: 'categories',
							element: <CategoriesTable />,
						},
						{
							path: 'attributes',
							element: <AttributesTable />,
						},
						{
							path: 'attributes-templates',
							element: <AttributesTemplatesTable />,
						},
						{
							path: 'orders',
							element: <OrdersTable />,
						},
						{
							path: 'customers',
							element: <CustomersTable />,
						},
					],
				},
				{
					path: 'profile',
					element: <UserPage />,
					children: [
						{
							path: 'personal',
							element: <UserTabPersonal />,
						},
						{
							path: 'subscriptions',
							element: <UserTabPayment />,
						},
						{
							path: 'password',
							element: <UserTabPassword />,
						},
					],
				},
				{
					path: 'customer/:id/:shopId',
					element: <CustomerPage />,
					children: [
						{
							path: 'personal',
							element: <CustomerTabPersonal />,
						},
						{
							path: 'orders',
							element: <CustomerTabOrders />,
						},
						{
							path: 'contact-data-history',
							element: <CustomerTabContactDataHistory />,
						},
					],
				},
				// {
				// 	path: 'subscriptions',
				// 	element: <Subscriptions />,
				// 	children: [
				// 		{
				// 			path: 'plans',
				// 			element: <Plans />,
				// 		},
				// 	],
				// },
			],
		},
	],
};

export default MainRoutes;
