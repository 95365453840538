import { types } from 'mobx-state-tree';
import { ImageModel } from '../sharedModels';

const ShopLocations = types.model('ShopLocations', {
	id: types.maybeNull(types.identifierNumber),
	telephoneNumbers: types.optional(types.array(types.string), []),
	location: types.optional(types.string, ''),
	workSchedule: types.optional(types.string, ''),
	images: types.optional(types.array(ImageModel), []),
});

const PaymentMethod = types.model('PaymentMethod', {
	id: types.maybeNull(types.identifierNumber),
	name: types.maybeNull(types.string),
	supportedCurrencies: types.optional(types.array(types.string), []),
	url: types.maybeNull(types.string),
});

const OnlinePaymentMethods = types.model('OnlinePaymentMethods', {
	id: types.maybeNull(types.identifierNumber),
	token: types.optional(types.string, ''),
	shopId: types.maybeNull(types.number),
	active: types.optional(types.boolean, false),
	paymentMethodId: types.maybeNull(types.number),
	paymentData: types.optional(types.frozen(), {}),
});

const Parameters = types.model('Parameters', {
	id: types.maybeNull(types.number),
	shopId: types.maybeNull(types.number),
	welcomeMessage: types.maybeNull(types.string),
	openShopButtonText: types.maybeNull(types.string),
	openShopMessage: types.maybeNull(types.string),
	startCommandDescription: types.maybeNull(types.string),
	openShopCommandDescription: types.maybeNull(types.string),
	fullPrepayMessage: types.maybeNull(types.string),
	partPrepayMessage: types.maybeNull(types.string),
	startButtonText: types.maybeNull(types.string),
	menuButtonText: types.maybeNull(types.string),
	contactsButtonText: types.maybeNull(types.string),
	aboutButtonText: types.maybeNull(types.string),
	contactUsButtonText: types.maybeNull(types.string),
	contactUsMessage: types.maybeNull(types.string),
	contactsMessage: types.maybeNull(types.string),
	deliveryMethodTypes: types.optional(types.array(types.string), []),
	paymentMethodTypes: types.optional(types.array(types.string), []),
	scripts: types.frozen(),
});

const Shop = types.model('Shop', {
	id: types.maybeNull(types.identifierNumber),
	title: types.optional(types.string, ''),
	description: types.optional(types.string, ''),
	avatar: types.maybeNull(ImageModel),
	telegramShopBotUsername: types.optional(types.string, ''),
	telegramShopBotToken: types.optional(types.string, ''),
	socialNetworkLinks: types.optional(types.array(types.string), []),
	telephoneNumbers: types.optional(types.array(types.string), []),
	images: types.optional(types.array(ImageModel), []),
	shopLocations: types.optional(types.array(ShopLocations), []),
	priceRange: types.frozen({
		minPrice: types.maybeNull(types.number),
		maxPrice: types.maybeNull(types.number),
	}),
	enableVirtualAssistant: types.optional(types.boolean, false),
	enableFormatTitle: types.optional(types.boolean, true),
	parameters: types.maybeNull(Parameters),
	status: types.optional(types.string, ''),
	contactUsUrl: types.optional(types.string, ''),
	minOrderAmount: types.maybeNull(types.number),
	language: types.optional(types.string, 'uk'),
	currency: types.optional(types.string, 'UAH'),
	userId: types.maybeNull(types.number),
	onlinePaymentMethods: types.optional(types.array(OnlinePaymentMethods), []),
	paymentTypes: types.optional(types.array(types.string), []),
});

export { Shop, ShopLocations, PaymentMethod };
