import { types } from 'mobx-state-tree';
import ProductsStoreActions from './actions';
import {
	Product,
	ProductSearchQuery,
	AutocompleteProduct,
	ModalState,
} from './model';
import { Meta } from '../sharedModels';

const ProductsStore = types
	.model('ProductsStore', {
		items: types.optional(types.array(Product), []),
		autocompleteProducts: types.optional(types.array(AutocompleteProduct), []),
		meta: types.optional(Meta, {}),
		searchQuery: types.optional(ProductSearchQuery, {}),
		selectedRows: types.optional(types.array(types.number), []),
		toDeleteRows: types.optional(types.array(types.number), []),
		modalState: types.optional(types.maybeNull(ModalState), null),
		autocompleteSearchQuery: types.optional(ProductSearchQuery, {}),
		isLoaded: types.optional(types.boolean, false),
		isLoading: types.optional(types.boolean, false),
	})
	.actions(ProductsStoreActions);

export default ProductsStore;
