import { useTheme } from '@mui/material/styles';
import logoDark from '../../../assets/images/logos/teleshop-logo-1-for-white-bg.svg';
import logo from '../../../assets/images/logos/teleshop-logo-1-for-black-bg.svg';

function LogoMain() {
	const theme = useTheme();

	return (
		<img
			src={theme.palette.mode === 'dark' ? logo : logoDark}
			alt="Teleshop"
			width="130px"
			style={{ marginTop: '8px' }}
		/>
	);
}

export default LogoMain;
