import { types } from 'mobx-state-tree';

const Statistic = types.model('Statistic', {
	totalShops: types.maybeNull(types.number),
	totalCustomers: types.maybeNull(types.number),
	activeCustomers: types.maybeNull(types.number),
});

const User = types.model('User', {
	id: types.maybeNull(types.identifierNumber),
	firstName: types.optional(types.string, ''),
	lastName: types.optional(types.string, ''),
	email: types.optional(types.string, ''),
	isEmailConfirmed: types.optional(types.boolean, false),
	telegramUsername: types.optional(types.string, ''),
	telegramUserId: types.optional(types.string, ''),
	activeShopId: types.maybeNull(types.number),
	phoneNumber: types.optional(types.string, ''),
	statistic: types.maybeNull(Statistic),
});

export { User };
