import { useTheme } from '@mui/material/styles';
import logoIcon from '../../../assets/images/logos/teleshop-icon-1-for-black-bg.png';
import logoIconDark from '../../../assets/images/logos/teleshop-icon-1-for-white-bg.png';

function LogoIcon() {
	const theme = useTheme();

	return (
		<img
			src={theme.palette.mode === 'dark' ? logoIcon : logoIconDark}
			alt="Teleshop"
			width="33px"
			style={{ marginTop: '8px' }}
		/>
	);
}

export default LogoIcon;
