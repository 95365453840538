import { types } from 'mobx-state-tree';

const ImportMeta = types.model('ImportMeta', {
	shopId: types.optional(types.number, 0),
	isImportInProgress: types.optional(types.boolean, false),
	downloadStarted: types.optional(types.boolean, false),
	downloadFinished: types.optional(types.boolean, false),
	catalogDeleteStarted: types.optional(types.boolean, false),
	catalogDeleteFinished: types.optional(types.boolean, false),
	categoriesProcessingStarted: types.optional(types.boolean, false),
	categoriesProcessingFinished: types.optional(types.boolean, false),
	productsProcessingStarted: types.optional(types.boolean, false),
	productsProcessingFinished: types.optional(types.boolean, false),
	productsCreateStarted: types.optional(types.boolean, false),
	productsCreateFinished: types.optional(types.boolean, false),
	syncAttributeStarted: types.optional(types.boolean, false),
	syncAttributeFinished: types.optional(types.boolean, false),
	categoriesCount: types.optional(types.integer, 0),
	productsCount: types.optional(types.integer, 0),
	createProductsCount: types.optional(types.integer, 0),
	syncAttributesCount: types.optional(types.integer, 0),
	processedCategories: types.optional(types.integer, 0),
	processedProducts: types.optional(types.integer, 0),
	createdProducts: types.optional(types.integer, 0),
	syncedAttributes: types.optional(types.integer, 0),
	validationErrors: types.optional(types.array(types.frozen()), []),
});

export { ImportMeta };
