import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Translate = ({ value }) => {
	const { t } = useTranslation();

	return t(value);
};

Translate.propTypes = {
	value: PropTypes.string.isRequired,
};

export default Translate;
