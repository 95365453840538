import { types } from 'mobx-state-tree';
import AttributesStoreActions from './actions';
import { Attribute, AttributeSearchQuery, ModalState } from './model';
import { Meta } from '../sharedModels';

const AttributesStore = types
	.model('AttributesStore', {
		items: types.optional(types.array(Attribute), []),
		allAttributes: types.optional(types.array(Attribute), []),
		meta: types.optional(Meta, () => ({
			currentPage: 1,
			itemCount: 0,
			itemsPerPage: 10,
			totalItems: 0,
			totalPages: 1,
		})),
		modalState: types.optional(types.maybeNull(ModalState), null),
		searchQuery: types.optional(AttributeSearchQuery, {}),
		selectedRows: types.optional(types.array(types.number), []),
		toDeleteRows: types.optional(types.array(types.number), []),
		isLoaded: types.optional(types.boolean, false),
		isLoading: types.optional(types.boolean, false),
	})
	.actions(AttributesStoreActions);

export default AttributesStore;
