export const capitalizeFirstLetter = (string) =>
	string.charAt(0).toUpperCase() + string.slice(1);

export const validateEmail = (email) => {
	return email.match(
		/* eslint-disable */
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		/* eslint-enable */
	);
};

export const formatTelegramBotUsername = (username) =>
	username.startsWith('@') ? username.substring(1) : username;

export const generateRandomString = () => {
	const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
	let result = '';
	for (let i = 0; i < 20; i += 1) {
		result += chars[Math.floor(Math.random() * chars.length)];
	}
	return result;
};

export const getImageAsBinary = (image) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(image);
		reader.onload = () => {
			const arrayBuffer = reader.result;
			resolve(new Uint8Array(arrayBuffer));
		};
	});
};

export const initializeSearchQuery = (
	itemTitle,
	searchQuery,
	setSearchQuery,
	setHasQueryBeenApplied,
) => {
	const query = sessionStorage.getItem(itemTitle);
	if (query) {
		const newQuery = { ...JSON.parse(query), search: '', page: 1 };
		const currentQuery = searchQuery;

		if (JSON.stringify(currentQuery) !== JSON.stringify(newQuery)) {
			setSearchQuery(newQuery);
		}
	}
	setHasQueryBeenApplied(true);
};

export const formatProductTitle = ({ title, attributes }) => {
	if (!title) return '';
	if (attributes) {
		const colorAttribute = attributes.find(
			({ attribute }) => attribute.type === 'color',
		);
		const updatedAttributes = attributes.filter(
			({ attribute }) => attribute.isVariant && attribute.type !== 'color',
		);

		if (colorAttribute) {
			updatedAttributes.unshift(colorAttribute);
		}

		return `${capitalizeFirstLetter(title)} ${updatedAttributes
			.map(({ value }) => (value?.value ? value.value.split(',')[0] : ''))
			.join(' ')}`;
	}

	return capitalizeFirstLetter(title);
};

export const categoryTitleFormater = (title, translateFunction) =>
	title === 'default' ? translateFunction('tables.categories.default') : title;

export const isBooleanString = (value) => {
	if (value === 'false') return false;
	if (value === 'true') return true;
	return value || null;
};

export const isStringBoolean = (value) => {
	if (value === false) return 'false';
	if (value === true) return 'true';
	return value;
};

export const prepareOrderItemsProducts = (data) => {
	return data.map((item) => {
		item.sellStatus = item.product?.sellStatus || 'unavailable';
		if (!item.product) {
			item.product = {
				title: item.title,
			};
		}
		return item;
	});
};

export const getDaysDiff = (date) => {
	const today = new Date();

	const timeDiff = date.getTime() - today.getTime();
	const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));

	return Math.abs(daysRemaining);
};

export const getUsernameFromEmail = (email) => {
	if (!email) return '-';
	return email.split('@')[0];
};

export const disableWheelEvent = (e) => {
	e.target.addEventListener('wheel', (event) => event.preventDefault(), {
		passive: false,
	});
};
