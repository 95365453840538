import { toJS } from 'mobx';
import { flow, getEnv, getParent } from 'mobx-state-tree';

const AttributesStoreActions = (self) => ({
	getAll: flow(function* getAll() {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			const { data } = yield api.attributes.getAll(shopId);

			self.allAttributes = data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	getAllWithPagination: flow(function* getAllWithPagination() {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			self.isLoading = true;
			const {
				data: { items, meta },
			} = yield api.attributes.getAllWithPagination(shopId, self.searchQuery);

			self.meta = {
				...meta,
				columnVisibility: toJS(self.searchQuery.columnVisibility),
				columnOrder: toJS(self.searchQuery.columnOrder),
				columnDimensions: toJS(self.searchQuery.columnDimensions),
				pinnedColumns: toJS(self.searchQuery.pinnedColumns),
			};
			self.items = items;
			self.isLoading = false;
			self.isLoaded = true;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	getAllFormatted: flow(function* getAllFormatted() {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			const { data } = yield api.attributes.getAllFormatted(shopId);

			self.allAttributes = data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	create: flow(function* create(payload) {
		const { api } = getEnv(self);
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		try {
			yield api.attributes.create(shopId, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error?.response?.status || error);
		}
	}),

	update: flow(function* update(id, payload) {
		const { api } = getEnv(self);

		try {
			yield api.attributes.update(id, payload);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error?.response?.status || error);
		}
	}),

	deleteOne: flow(function* deleteOne(id) {
		const { api } = getEnv(self);

		try {
			yield api.attributes.delete(id);
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	deleteMultiple: flow(function* deleteMultiple(shopId, attributeIds) {
		const { api } = getEnv(self);

		try {
			yield api.attributes.deleteMultiple({ shopId, attributeIds });
			yield self.getAllWithPagination();
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	deleteToDeleteRows: flow(function* deleteToDeleteRows(rows) {
		const {
			shopsStore: {
				activeShop: { id: shopId },
			},
		} = getParent(self);

		if (rows.length > 1) {
			yield self.deleteMultiple(shopId, rows);
		} else {
			yield self.deleteOne(rows[0]);
		}
		self.selectedRows.clear();
		self.toDeleteRows.clear();
	}),

	setSearchQuery: (value) => {
		const query = { ...self.searchQuery, ...value };
		self.searchQuery = query;
		sessionStorage.setItem('attributesSearchQuery', JSON.stringify(query));
	},

	setSelectedRows: (rows) => {
		self.selectedRows = rows;
	},

	setToDeleteRows: (rows) => {
		self.toDeleteRows = rows;
	},

	setModalState(action) {
		if (action === null) {
			self.modalState = null;
		} else {
			self.modalState = {
				action,
			};
		}
	},
});

export default AttributesStoreActions;
