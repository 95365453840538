import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import Profile from './Profile';
import { ActiveShopDropdown } from './ActiveShop';
import { Notification } from './Notification';
// import Subscriptions from './Subscriptions';
import MobileSection from './MobileSection';
import { AddShop } from './AddShop';
import { useStores } from '../../../../../stores/context';
import './index.scss';

const HeaderContent = observer(() => {
	const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	const {
		shopsStore: { findAllByUserId, shops, activeShop, setActiveShop },
		authStore: { user, isLoggedIn },
		userStore: { updateActiveShopId },
		snackbarStore: { openSnackbar },
	} = useStores();

	useEffect(() => {
		if (isLoggedIn)
			findAllByUserId().catch((e) => {
				openSnackbar({
					open: true,
					message: e.message,
					variant: 'alert',
					alert: { color: 'error' },
					close: false,
				});
			});
	}, [user]);

	useEffect(() => {
		const userShops = toJS(shops);
		if (userShops.length) {
			if (!activeShop.id) {
				let shop;
				if (user?.activeShopId)
					shop = userShops.find((item) => item.id === user.activeShopId);
				setActiveShop(shop || userShops[0]);
			} else {
				updateActiveShopId({ activeShopId: activeShop.id });
			}
		}
	}, [shops.length]);

	return (
		<div className="header-content">
			{isSmScreen ? (
				<>
					<Profile />
					<Notification />
					<MobileSection />
				</>
			) : (
				<>
					<AddShop />
					<ActiveShopDropdown />
					<Notification />
					<Profile />
				</>
			)}
		</div>
	);
});

export default HeaderContent;
