/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import {
	Box,
	Button,
	ButtonBase,
	CardContent,
	Chip,
	ClickAwayListener,
	Grid,
	Paper,
	Popper,
	Stack,
	Typography,
} from '@mui/material';
import { CheckSquareFilled, WarningFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import avatar from '../../../../../../assets/images/users/avatar.png';
import Transitions from '../../../../../@extended/Transitions';
import Avatar from '../../../../../@extended/Avatar';
import MainCard from '../../../../../common/MainCard';
import ProfileTab from './ProfileTab';
import { useStores } from '../../../../../../stores/context';
import { getUsernameFromEmail } from '../../../../../../utils/helpers';

function TabPanel({ children, value, index, ...other }) {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`profile-tabpanel-${index}`}
			aria-labelledby={`profile-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

TabPanel.defaultProps = {
	children: null,
};

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const Profile = observer(() => {
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();
	const {
		authStore: { logout, user, verifyEmail },
		snackbarStore: { openSnackbar },
	} = useStores();

	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(false);
	const [timer, setTimer] = useState(null);

	useEffect(() => {
		if (timer) {
			const id = setTimeout(() => {
				setIsVerifyButtonDisabled(false);
				setTimer(null);
			}, 60000);

			return () => clearTimeout(id);
		}

		return undefined;
	}, [timer]);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleOpenSnackbar = (message, color) => {
		openSnackbar({
			open: true,
			message,
			variant: 'alert',
			alert: { color },
			close: false,
		});
	};

	const handleVerifyEmail = async () => {
		try {
			await verifyEmail();
			handleOpenSnackbar(t('profile.email.success-message'), 'success');
			setIsVerifyButtonDisabled(true);
			setTimer(true);
		} catch ({ message }) {
			handleOpenSnackbar(message, 'error');
		}
	};

	const handleLogout = () => {
		logout();
		navigate('/login');
	};

	const iconBackColorOpen =
		theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';

	return (
		<Box sx={{ flexShrink: 0, ml: 0.75 }}>
			<ButtonBase
				sx={{
					p: 0.25,
					bgcolor: open ? iconBackColorOpen : 'transparent',
					borderRadius: 1,
					'&:hover': {
						bgcolor:
							theme.palette.mode === 'dark'
								? 'secondary.light'
								: 'secondary.lighter',
					},
					'&:focus-visible': {
						outline: `2px solid ${theme.palette.secondary.dark}`,
						outlineOffset: 2,
					},
				}}
				aria-label="open profile"
				ref={anchorRef}
				aria-controls={open ? 'profile-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
			>
				<Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
					<Avatar alt="profile user" src={avatar} size="xs" />
					<Typography
						variant="subtitle1"
						sx={{
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							maxWidth: '150px',
						}}
					>
						{user?.telegramUsername || getUsernameFromEmail(user?.email)}
					</Typography>
				</Stack>
			</ButtonBase>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 9],
							},
						},
					],
				}}
			>
				{({ TransitionProps }) => (
					<Transitions type="fade" in={open} {...TransitionProps}>
						{open && (
							<Paper
								sx={{
									boxShadow: theme.customShadows.z1,
									width: 290,
									minWidth: 240,
									maxWidth: 290,
									[theme.breakpoints.down('md')]: {
										maxWidth: 250,
									},
								}}
							>
								<ClickAwayListener onClickAway={handleClose}>
									<MainCard elevation={0} border={false} content={false}>
										<CardContent sx={{ px: 2.5, pt: 3 }}>
											<Grid
												container
												justifyContent="space-between"
												alignItems="center"
												sx={{
													[theme.breakpoints.down('md')]: {
														justifyContent: 'space-around',
													},
												}}
											>
												<Grid item>
													<Stack
														direction="row"
														spacing={1.25}
														alignItems="center"
													>
														<Avatar
															alt="profile user"
															src={avatar}
															sx={{ width: 32, height: 32 }}
														/>
														<Stack>
															<Typography
																variant="h6"
																sx={{
																	overflow: 'hidden',
																	whiteSpace: 'nowrap',
																	textOverflow: 'ellipsis',
																	maxWidth: '140px',
																}}
															>
																{user?.telegramUsername ||
																	getUsernameFromEmail(user?.email)}
															</Typography>
															<Typography variant="body2" color="textSecondary">
																{user?.email}
															</Typography>
															<Stack
																direction="row"
																spacing={1.25}
																alignItems="center"
															>
																{user.isEmailConfirmed ? (
																	<Chip
																		icon={<CheckSquareFilled />}
																		color="success"
																		variant="outlined"
																		label={t('profile.email.verified')}
																		sx={{
																			height: 20,
																			marginTop: '2px',
																			fontSize: '10px',
																		}}
																	/>
																) : (
																	<Button
																		startIcon={<WarningFilled />}
																		color="warning"
																		onClick={handleVerifyEmail}
																		variant="outlined"
																		sx={{
																			height: 20,
																			marginTop: '2px',
																			fontSize: '10px',
																		}}
																		disabled={isVerifyButtonDisabled}
																	>
																		{t('profile.email.verify')}
																	</Button>
																)}
															</Stack>
														</Stack>
													</Stack>
												</Grid>
											</Grid>
										</CardContent>
										<ProfileTab handleLogout={handleLogout} />
									</MainCard>
								</ClickAwayListener>
							</Paper>
						)}
					</Transitions>
				)}
			</Popper>
		</Box>
	);
});

export default Profile;
