import { UserOutlined } from '@ant-design/icons';
import Translate from '../../../utils/i18n';

const menu = {
	id: 'menu',
	type: 'group',
	children: [
		{
			id: 'personal',
			title: <Translate value="menu-items.profile" />,
			type: 'item',
			url: '/profile/personal',
			icon: UserOutlined,
			breadcrumbs: false,
		},
	],
};

export default menu;
