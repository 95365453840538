import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../stores/context';

const GuestGuard = observer(({ children }) => {
	const {
		authStore: { isLoggedIn },
	} = useStores();

	const navigate = useNavigate();

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/tables/orders');
		}
	}, [isLoggedIn, navigate]);

	return children;
});

GuestGuard.defaultProps = {
	children: null,
};

GuestGuard.propTypes = {
	children: PropTypes.node,
};

export default GuestGuard;
