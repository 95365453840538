import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from '../../../stores/context';
import './index.scss';

export const ThemeSelector = observer(() => {
	const theme = useTheme();
	const { t } = useTranslation();

	const {
		appStore: { setThemeMode, themeMode },
	} = useStores();

	const [currentTheme, setCurrentTheme] = useState(themeMode);

	const handleSelecttheme = (event) => {
		setThemeMode(event.target.value);
		setCurrentTheme(event.target.value);
	};

	return (
		<div className="theme-selector">
			<p className="theme-selector__title">{t('theme.title')}</p>
			<div className="theme-selector__actions">
				<ToggleButtonGroup
					value={currentTheme}
					color="primary"
					exclusive
					onChange={handleSelecttheme}
					aria-label="text alignment"
					sx={{
						'& .MuiToggleButton-root': {
							height: '24px',
							'&:not(.Mui-selected)': {
								borderTopColor: 'transparent',
								borderBottomColor: 'transparent',
							},
							'&:first-of-type': {
								borderLeftColor: 'transparent',
							},
							'&:last-of-type': {
								borderRightColor: 'transparent',
							},
							'&.Mui-selected': {
								borderColor: 'inherit',
								borderLeftColor: theme.palette.primary.main,
								'&:hover': {
									bgcolor: theme.palette.primary.light,
								},
							},
							'&:hover': {
								bgcolor: 'transparent',
								borderColor: theme.palette.primary.main,
								borderLeftColor: theme.palette.primary.main,
								zIndex: 2,
							},
						},
					}}
				>
					<ToggleButton value="light" aria-label="Light">
						{t('theme.light')}
					</ToggleButton>
					<ToggleButton value="dark" aria-label="Dark">
						{t('theme.dark')}
					</ToggleButton>
				</ToggleButtonGroup>
			</div>
		</div>
	);
});
