import { types } from 'mobx-state-tree';
import OrdersStoreActions from './actions';
import { Order, OrderSearchQuery } from './model';
import { Meta } from '../sharedModels';

const OrdersStore = types
	.model('OrdersStore', {
		items: types.optional(types.array(Order), []),
		meta: types.optional(Meta, {}),
		searchQuery: types.optional(OrderSearchQuery, {}),
		isLoaded: types.optional(types.boolean, false),
		isLoading: types.optional(types.boolean, false),
	})
	.actions(OrdersStoreActions);

export default OrdersStore;
