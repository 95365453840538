import { UsergroupAddOutlined } from '@ant-design/icons';
import Translate from '../../../utils/i18n';

const audience = {
	id: 'group-audience',
	title: <Translate value="menu-items.audience" />,
	type: 'group',
	children: [
		{
			id: 'customers',
			title: <Translate value="menu-items.customers" />,
			type: 'item',
			url: '/tables/customers',
			icon: UsergroupAddOutlined,
			breadcrumbs: false,
		},
	],
};

export default audience;
