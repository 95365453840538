import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
	Avatar,
	Chip,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/context';

export const NavItem = observer(({ item, level }) => {
	const theme = useTheme();
	const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

	const {
		menuStore: { isDrawerOpen, openedNavItem, openNavItem, setOpenDrawer },
	} = useStores();

	const location = useLocation();

	let itemTarget = '_self';
	if (item.target) {
		itemTarget = '_blank';
	}

	let listItemProps = {
		component: forwardRef((props, ref) => (
			<Link {...props} to={item.url} target={itemTarget} ref={ref} />
		)),
	};

	listItemProps.component.displayName = 'ItemLink';

	if (item?.external) {
		listItemProps = { component: 'a', href: item.url, target: itemTarget };
	}

	const Icon = item.icon;
	const itemIcon = item.icon ? (
		<Icon style={{ fontSize: isDrawerOpen ? '1rem' : '1.25rem' }} />
	) : (
		false
	);

	const isSelected = useMemo(() => openedNavItem === item.id, [openedNavItem]);

	const { pathname } = document.location;

	useEffect(() => {
		if (pathname.includes(item.url)) {
			openNavItem(item.id);
		}
	}, [location.pathname]);

	const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
	const iconSelectedColor =
		theme.palette.mode === 'dark' && isDrawerOpen
			? 'text.primary'
			: 'primary.main';

	return (
		<ListItemButton
			{...listItemProps}
			onClick={() => {
				if (matchDownSM) setOpenDrawer(false);
				openNavItem(item.id);
			}}
			disabled={item.disabled}
			selected={isSelected}
			sx={{
				zIndex: 1201,
				pl: isDrawerOpen ? `${level * 28}px` : 1.5,
				py: !isDrawerOpen && level === 1 ? 1.25 : 1,
				...(isDrawerOpen && {
					'&:hover': {
						bgcolor:
							theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
					},
					'&.Mui-selected': {
						bgcolor:
							theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
						borderRight: `2px solid ${theme.palette.primary.main}`,
						color: iconSelectedColor,
						'&:hover': {
							color: iconSelectedColor,
							bgcolor:
								theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
						},
					},
				}),
				...(!isDrawerOpen && {
					'&:hover': {
						bgcolor: 'transparent',
					},
					'&.Mui-selected': {
						'&:hover': {
							bgcolor: 'transparent',
						},
						bgcolor: 'transparent',
					},
				}),
			}}
		>
			{itemIcon && (
				<ListItemIcon
					sx={{
						minWidth: 28,
						color: isSelected ? iconSelectedColor : textColor,
						...(!isDrawerOpen && {
							borderRadius: 1.5,
							width: 36,
							height: 36,
							alignItems: 'center',
							justifyContent: 'center',
							'&:hover': {
								bgcolor:
									theme.palette.mode === 'dark'
										? 'secondary.light'
										: 'secondary.lighter',
							},
						}),
						...(!isDrawerOpen &&
							isSelected && {
								bgcolor:
									theme.palette.mode === 'dark'
										? 'primary.900'
										: 'primary.lighter',
								'&:hover': {
									bgcolor:
										theme.palette.mode === 'dark'
											? 'primary.darker'
											: 'primary.lighter',
								},
							}),
					}}
				>
					{itemIcon}
				</ListItemIcon>
			)}
			{(isDrawerOpen || (!isDrawerOpen && level !== 1)) && (
				<ListItemText
					primary={
						<Typography
							variant="h6"
							sx={{ color: isSelected ? iconSelectedColor : textColor }}
						>
							{item.title}
						</Typography>
					}
				/>
			)}
			{(isDrawerOpen || (!isDrawerOpen && level !== 1)) && item.chip && (
				<Chip
					color={item.chip.color}
					variant={item.chip.variant}
					size={item.chip.size}
					label={item.chip.label}
					avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
				/>
			)}
		</ListItemButton>
	);
});

NavItem.propTypes = {
	item: PropTypes.instanceOf(Object).isRequired,
	level: PropTypes.number.isRequired,
};
