import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery } from '@mui/material';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import IconButton from '../../../@extended/IconButton';
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent/index.jsx';

function Header({ open, handleDrawerToggle }) {
	const theme = useTheme();
	const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
	const headerContent = useMemo(() => <HeaderContent />, []);

	const iconBackColorOpen =
		theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
	const iconBackColor =
		theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

	const mainHeader = (
		<Toolbar>
			<IconButton
				aria-label="open drawer"
				onClick={handleDrawerToggle}
				edge="start"
				color="secondary"
				variant="light"
				sx={{
					color: 'text.primary',
					bgcolor: open ? iconBackColorOpen : iconBackColor,
					ml: { xs: 0, lg: -2 },
				}}
			>
				{!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
			</IconButton>
			{headerContent}
		</Toolbar>
	);

	const appBar = {
		position: 'fixed',
		color: 'inherit',
		elevation: 0,
		sx: {
			borderBottom: `1px solid ${theme.palette.divider}`,
			zIndex: 1200,
			// boxShadow: theme.customShadows.z1
		},
	};

	if (!matchDownSM) {
		return (
			<AppBarStyled open={open} {...appBar}>
				{mainHeader}
			</AppBarStyled>
		);
	}
	return <AppBar {...appBar}>{mainHeader}</AppBar>;
}

Header.propTypes = {
	open: PropTypes.bool.isRequired,
	handleDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
