import { types } from 'mobx-state-tree';
import CategoriesStoreActions from './actions';
import {
	Category,
	CategorySearchQuery,
	AutocompleteCategory,
	ModalState,
} from './model';
import { Meta } from '../sharedModels';

const CategoriesStore = types
	.model('CategoriesStore', {
		items: types.optional(types.array(Category), []),
		allCategories: types.optional(types.array(Category), []),
		autocompleteCategories: types.optional(
			types.array(AutocompleteCategory),
			[],
		),
		modalState: types.optional(types.maybeNull(ModalState), null),
		meta: types.optional(Meta, {}),
		searchQuery: types.optional(CategorySearchQuery, {}),
		autocompleteSearchQuery: types.optional(CategorySearchQuery, {}),
		selectedRows: types.optional(types.array(types.number), []),
		toDeleteRows: types.optional(types.array(types.number), []),
		isLoaded: types.optional(types.boolean, false),
		isLoading: types.optional(types.boolean, false),
	})
	.actions(CategoriesStoreActions);

export default CategoriesStore;
