import { types } from 'mobx-state-tree';
import NotificationsStoreActions from './actions';
import { Notification } from './model';

const NotificationsStore = types
	.model('NotificationsStore', {
		notifications: types.optional(types.array(Notification), []),
	})
	.actions(NotificationsStoreActions);

export default NotificationsStore;
