import {
	ImportOutlined,
	CloudServerOutlined,
	CodeSandboxOutlined,
} from '@ant-design/icons';
import Translate from '../../../utils/i18n';

const tools = {
	id: 'tools',
	title: <Translate value="menu-items.tools" />,
	type: 'group',
	children: [
		{
			id: 'import-export',
			title: <Translate value="menu-items.import-export" />,
			type: 'item',
			url: '/import-export',
			icon: ImportOutlined,
			breadcrumbs: false,
		},
		{
			id: 'api',
			title: <Translate value="menu-items.api" />,
			type: 'item',
			icon: CloudServerOutlined,
			url: '/api/token',
			breadcrumbs: false,
		},
		{
			id: 'webhooks',
			title: <Translate value="menu-items.webhooks" />,
			type: 'item',
			icon: CodeSandboxOutlined,
			breadcrumbs: false,
			disabled: true,
		},
	],
};

export default tools;
