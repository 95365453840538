// ==============================|| OVERRIDES - BUTTON GROUP ||============================== //

export default function ButtonGroup() {
	return {
		MuiButtonGroup: {
			defaultProps: {
				disableRipple: true,
			},
		},
	};
}
