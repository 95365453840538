import { useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { useStores } from '../stores/context';

const useSocketNotifications = (room) => {
	const {
		notificationsStore: { setNotifications },
		importStore: { setMeta },
		shopsStore: { activeShop },
		authStore: {
			user: { id },
		},
	} = useStores();
	const socketRef = useRef(null);

	useEffect(() => {
		socketRef.current = io(
			`wss://${process.env.REACT_APP_BASE_URL.split('://')[1]}`,
			{
				transports: ['websocket'],
			},
		);

		socketRef.current.on('connect', () => {
			console.log('Connected to the IO server!');
		});

		socketRef.current.on('connect_error', (error) => {
			console.log(`Connected to the IO server error due to ${error.message}`);
		});

		return () => {
			if (socketRef.current) {
				socketRef.current.close();
			}
		};
	}, []);

	useEffect(() => {
		const handleNotification = (notification) => {
			const allNotifications =
				JSON.parse(localStorage.getItem('teleshopNotifications')) || {};
			const userNotifications = allNotifications[id] || [];
			allNotifications[id] = [notification, ...userNotifications];
			setNotifications(allNotifications[id]);
			localStorage.setItem(
				'teleshopNotifications',
				JSON.stringify(allNotifications),
			);
		};

		const handleImport = ({ type, data }) => {
			const importMeta = JSON.parse(localStorage.getItem('importMeta')) || [];
			let shopIndex = importMeta.findIndex(
				(meta) => meta.shopId === activeShop.id,
			);

			if (shopIndex === -1 && type === 'start') {
				importMeta.push({ shopId: activeShop.id });
				shopIndex = importMeta.length - 1;
			}

			const updateMeta = (key, value) => {
				if (shopIndex !== -1) {
					importMeta[shopIndex][key] = value;
					setMeta([...importMeta]);
				}
			};

			switch (type) {
				case 'start':
				case 'finish':
					updateMeta('isImportInProgress', type === 'start');
					break;
				case 'categories-items-count':
					updateMeta('categoriesCount', data);
					break;
				case 'product-items-count':
					updateMeta('productsCount', data);
					break;
				case 'create-product-items-count':
					updateMeta('createProductsCount', data);
					break;
				case 'sync-attribute-items-count':
					updateMeta('syncAttributesCount', data);
					break;
				case 'process-product-error':
					updateMeta('validationErrors', data);
					break;
				case 'file-download-started':
					updateMeta('downloadStarted', true);
					break;
				case 'file-download-finished':
					updateMeta('downloadFinished', true);
					break;
				case 'catalog-delete-started':
					updateMeta('catalogDeleteStarted', true);
					break;
				case 'catalog-delete-finished':
					updateMeta('catalogDeleteFinished', true);
					break;
				case 'categories-processing-started':
					updateMeta('categoriesProcessingStarted', true);
					break;
				case 'categories-processing-finished':
					updateMeta('categoriesProcessingFinished', true);
					break;
				case 'products-processing-started':
					updateMeta('productsProcessingStarted', true);
					break;
				case 'products-processing-finished':
					updateMeta('productsProcessingFinished', true);
					break;
				case 'products-create-started':
					updateMeta('productsCreateStarted', true);
					break;
				case 'products-create-finished':
					updateMeta('productsCreateFinished', true);
					break;
				case 'sync-attribute-started':
					updateMeta('syncAttributeStarted', true);
					break;
				case 'sync-attribute-finished':
					updateMeta('syncAttributeFinished', true);
					break;
				case 'category-success':
					updateMeta(
						'processedCategories',
						(importMeta[shopIndex]?.processedCategories || 0) + 1,
					);
					break;
				case 'process-product-success':
					updateMeta(
						'processedProducts',
						(importMeta[shopIndex]?.processedProducts || 0) + 1,
					);
					break;
				case 'create-product-success':
					updateMeta(
						'createdProducts',
						(importMeta[shopIndex]?.createdProducts || 0) + 1,
					);
					break;
				case 'sync-attribute-success':
					updateMeta(
						'syncedAttributes',
						(importMeta[shopIndex]?.syncedAttributes || 0) + 1,
					);
					break;
				default:
					break;
			}
		};

		socketRef.current.on('notification', handleNotification);
		socketRef.current.on('import', handleImport);

		return () => {
			socketRef.current.off('notification', handleNotification);
			socketRef.current.off('import', handleImport);
		};
	}, [activeShop.id]);

	useEffect(() => {
		if (socketRef.current) {
			socketRef.current.emit('leave-room', { room });

			if (room) {
				socketRef.current.emit('join-room', { room });
			}
		}

		return () => {
			if (socketRef.current) {
				socketRef.current.emit('leave-room', { room });
			}
		};
	}, [room]);
};

export default useSocketNotifications;
