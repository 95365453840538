import { types } from 'mobx-state-tree';
import { ProductAttribute } from '../attributesStore/model';
import { Category } from '../categoriesStore/model';
import { OrderBy, ImageModel } from '../sharedModels';

const Variant = types.model('Variant', {
	id: types.maybeNull(types.identifierNumber),
	sku: types.optional(types.string, ''),
	title: types.optional(types.string, ''),
	images: types.optional(types.array(ImageModel), []),
});

const Product = types.model('Product', {
	id: types.maybeNull(types.identifierNumber),
	sku: types.optional(types.string, ''),
	title: types.optional(types.string, ''),
	description: types.optional(types.string, ''),
	price: types.optional(types.number, 0),
	popularity: types.optional(types.number, 1),
	discountPrice: types.maybeNull(types.number),
	quantity: types.optional(types.number, 0),
	images: types.optional(types.array(ImageModel), []),
	categoryId: types.maybeNull(types.number),
	variantParentId: types.maybeNull(types.number),
	category: types.maybeNull(Category),
	rawAttributes: types.maybeNull(types.array(types.array(types.string))),
	attributes: types.maybeNull(types.array(ProductAttribute)),
	variants: types.maybeNull(types.array(Variant)),
	sellStatus: types.optional(types.string, ''),
	visibility: types.optional(types.string, ''),
	isHaveVariants: types.optional(types.boolean, false),
	stockControl: types.optional(types.boolean, true),
	enableFormatTitle: types.optional(types.boolean, true),
});

const AutocompleteProduct = types.model('AutocompleteProduct', {
	label: types.optional(types.string, ''),
	value: types.optional(Product, {}),
});

const ProductFilters = types.model('ProductFilters', {
	visibility: types.maybeNull(types.string),
	stockControl: types.maybeNull(types.boolean),
	sellStatus: types.maybeNull(types.string),
	category: types.maybeNull(types.string),
	lonely: types.maybeNull(types.boolean),
});

const ProductSearchQuery = types.model('ProductSearchQuery', {
	search: types.optional(types.string, ''),
	page: types.optional(types.number, 1),
	limit: types.optional(types.number, 10),
	order: types.optional(OrderBy, {}),
	filters: types.optional(ProductFilters, {}),
	columnVisibility: types.optional(types.frozen(), {}),
	columnOrder: types.optional(types.array(types.string), []),
	columnDimensions: types.optional(types.frozen(), {}),
	pinnedColumns: types.optional(types.frozen(), {}),
});

const ModalState = types.model('ModalState', {
	action: types.enumeration('Action', [
		'create',
		'update',
		'copy',
		'addAttributes',
		'addImages',
	]),
	product: types.maybeNull(Product),
});

export { Product, ProductSearchQuery, AutocompleteProduct, ModalState };
