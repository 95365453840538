import { types } from 'mobx-state-tree';
import ImportStoreActions from './actions';
import { ImportMeta } from './model';

const ImportStore = types
	.model('ImportStore', {
		metaByShop: types.optional(types.array(ImportMeta), []),
	})
	.actions(ImportStoreActions);

export default ImportStore;
