import { types } from 'mobx-state-tree';
import { OrderBy } from '../sharedModels';
import { Attribute } from '../attributesStore/model';

const AttributesTemplate = types.model('AttributesTemplate', {
	id: types.maybeNull(types.identifierNumber),
	name: types.maybeNull(types.string),
	createdAt: types.maybeNull(types.string),
	attributes: types.optional(types.array(Attribute), []),
});

const AttributesTemplatesSearchQuery = types.model(
	'AttributesTemplatesSearchQuery',
	{
		search: types.optional(types.string, ''),
		page: types.optional(types.number, 1),
		limit: types.optional(types.number, 10),
		order: types.optional(OrderBy, {}),
		columnVisibility: types.optional(types.frozen(), {}),
		columnOrder: types.optional(types.array(types.string), []),
		columnDimensions: types.optional(types.frozen(), {}),
		pinnedColumns: types.optional(types.frozen(), {}),
	},
);

const ModalState = types.model('ModalState', {
	action: types.enumeration('Action', ['update', 'create']),
	template: types.maybeNull(AttributesTemplate),
});

export { AttributesTemplate, AttributesTemplatesSearchQuery, ModalState };
