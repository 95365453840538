import sales from './sales';
import menu from './menu';
import tools from './tools';
import catalog from './catalog';
import audience from './audience';
import shops, { noShops } from './shops';

const menuItems = {
	items: [menu, tools, catalog, sales, audience, shops],
};

const menuItemsNoShops = {
	items: [noShops],
};

export { menuItemsNoShops };
export default menuItems;
