import { createContext, useContext } from 'react';

const RootStoreContext = createContext({});

export const RootStoreProvider = RootStoreContext.Provider;

export const useStores = () => {
	const stores = useContext(RootStoreContext);
	return {
		getStore: (storeName) => stores[storeName],
		...stores,
	};
};
