/* eslint-disable max-len */
import { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
	Box,
	ClickAwayListener,
	Grid,
	List,
	ListItemButton,
	ListItemText,
	Paper,
	Popper,
	Typography,
	useMediaQuery,
	Stack,
	Link,
} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import { CaretDownOutlined, ShopOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import IconButton from '../../../../../@extended/IconButton';
import Transitions from '../../../../../@extended/Transitions';
import { useStores } from '../../../../../../stores/context';

export const ActiveShopDropdown = observer(() => {
	const theme = useTheme();
	const { t } = useTranslation();
	const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

	const {
		shopsStore: { shops, activeShop, setActiveShop },
		userStore: { updateActiveShopId },
	} = useStores();

	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleListItemClick = (shop) => {
		setActiveShop(toJS(shop));
		updateActiveShopId({ activeShopId: shop.id });
		setOpen(false);
	};

	const iconBackColorOpen = 'grey.200';
	const iconBackColor = 'background.default';

	return (
		<Box sx={{ flexShrink: 0, ml: 0.75 }}>
			<Stack
				direction="row"
				spacing={1}
				alignItems="center"
				sx={{ paddingRight: 0.5 }}
			>
				<IconButton
					color="secondary"
					variant="light"
					sx={{
						width: 'auto',
						maxWidth: '200px',
						display: 'flex',
						justifyContent: 'space-between',
						bgcolor: open ? iconBackColorOpen : iconBackColor,
						color: 'text.primary',
					}}
					aria-label="Choose active shop"
					ref={anchorRef}
					aria-controls={open ? 'active-shop-grow' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
				>
					<ShopOutlined />
					<Typography
						sx={{
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							paddingX: '5px',
						}}
					>
						{activeShop.title || t('active-shop.choose')}
					</Typography>
					<CaretDownOutlined />
				</IconButton>
			</Stack>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [matchesXs ? 0 : 0, 9],
							},
						},
					],
				}}
			>
				{({ TransitionProps }) => (
					<Transitions type="fade" in={open} {...TransitionProps}>
						<Paper sx={{ boxShadow: theme.customShadows.z1 }}>
							<ClickAwayListener onClickAway={handleClose}>
								<List
									component="nav"
									sx={{
										p: 0,
										width: '100%',
										minWidth: 200,
										maxWidth: 290,
										maxHeight: 500,
										overflowY: 'auto',
										bgcolor: theme.palette.background.paper,
										borderRadius: 0.5,
										[theme.breakpoints.down('md')]: {
											maxWidth: 250,
										},
									}}
								>
									{shops?.length > 0 &&
										shops.map((shop) => (
											<ListItemButton
												key={shop.id}
												selected={activeShop.id === shop.id}
												onClick={() => handleListItemClick(shop)}
											>
												<ListItemText
													primary={
														<Grid container>
															<Grid
																container
																sx={{
																	alignItems: 'center',
																	flexDirection: 'row',
																}}
															>
																<IconButton
																	size="small"
																	aria-label="close"
																	color="inherit"
																	onClick={(e) => {
																		e.stopPropagation();
																		window
																			.open(
																				`https://t.me/${shop.telegramShopBotUsername.replace(
																					'@',
																					'',
																				)}`,
																				'_blank',
																			)
																			.focus();
																	}}
																	sx={{ mr: 1 }}
																>
																	<TelegramIcon />
																</IconButton>
																<Typography
																	noWrap
																	color="textPrimary"
																	sx={{ maxWidth: '180px' }}
																>
																	{shop.title}
																</Typography>
															</Grid>
															<Grid
																container
																sx={{
																	alignItems: 'center',
																	flexDirection: 'row',
																}}
															>
																<Typography noWrap color="textSecondary">
																	{`(ID: ${shop.id})`}
																</Typography>
																<Link
																	onClick={(e) => {
																		e.stopPropagation();
																	}}
																	href={`https://t.me/${shop.telegramShopBotUsername.replace(
																		'@',
																		'',
																	)}`}
																	underline="always"
																	target="_blank"
																	sx={{ ml: '8px' }}
																>
																	{`${shop.telegramShopBotUsername}`}
																</Link>
															</Grid>
														</Grid>
													}
												/>
											</ListItemButton>
										))}
								</List>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</Box>
	);
});
