import {
	AppstoreAddOutlined,
	EditOutlined,
	HomeOutlined,
	CreditCardOutlined,
	SettingOutlined,
	TruckOutlined,
} from '@ant-design/icons';
import Translate from '../../../utils/i18n';

const shops = {
	id: 'shops',
	title: <Translate value="menu-items.shops" />,
	type: 'group',
	children: [
		{
			id: 'shops-edit',
			title: <Translate value="menu-items.edit-shop" />,
			type: 'item',
			url: '/shops/edit',
			icon: EditOutlined,
			breadcrumbs: false,
		},
		{
			id: 'shops-locations',
			title: <Translate value="menu-items.locations" />,
			type: 'item',
			url: '/shops/locations',
			icon: HomeOutlined,
			breadcrumbs: false,
		},
		{
			id: 'shops-delivery-methods',
			title: <Translate value="menu-items.delivery-methods" />,
			type: 'item',
			url: '/shops/delivery-methods',
			icon: TruckOutlined,
			breadcrumbs: false,
		},
		{
			id: 'shops-payment-methods',
			title: <Translate value="menu-items.payment-methods" />,
			type: 'item',
			url: '/shops/payment-methods',
			icon: CreditCardOutlined,
			breadcrumbs: false,
		},
		{
			id: 'shops-parameters',
			title: <Translate value="menu-items.parameters" />,
			type: 'item',
			url: '/shops/parameters',
			icon: SettingOutlined,
			breadcrumbs: false,
		},
	],
};

const noShops = {
	id: 'shops',
	title: <Translate value="menu-items.shops" />,
	type: 'group',
	children: [
		{
			id: 'shops-add',
			title: <Translate value="menu-items.add-shop" />,
			type: 'item',
			url: '/shops/add',
			icon: AppstoreAddOutlined,
			breadcrumbs: false,
		},
	],
};

export { noShops };
export default shops;
