// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function ButtonBase() {
	return {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
	};
}
