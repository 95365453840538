import { types } from 'mobx-state-tree';
import AppStoreActions from './actions';

const AppStore = types
	.model('AppStore', {
		isOverlayVisible: types.optional(types.boolean, false),
		loading: types.optional(types.boolean, false),
		themeMode: types.optional(types.string, 'dark'),
	})
	.actions(AppStoreActions);

export default AppStore;
