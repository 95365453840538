import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, MenuItem, Select } from '@mui/material';
import uaIcon from '../../../assets/flags/ukraine.png';
import plIcon from '../../../assets/flags/poland.png';
import enIcon from '../../../assets/flags/united-states.png';
import kzIcon from '../../../assets/flags/kazakhstan.png';
import './index.scss';

export function LanguageSelector() {
	const { t, i18n } = useTranslation();

	const [currentLanguage, setCurrentLanguage] = useState(
		i18n.resolvedLanguage || 'uk',
	);

	const handleSelectLanguage = (event) => {
		i18n.changeLanguage(event.target.value);
		setCurrentLanguage(event.target.value);
	};

	const countries = [
		{ code: 'uk', name: 'Українська', icon: uaIcon },
		{ code: 'en', name: 'English', icon: enIcon },
		{ code: 'pl', name: 'Polski', icon: plIcon },
		{ code: 'kz', name: 'Қазақ', icon: kzIcon },
		{ code: 'ru', name: 'Русский', icon: '' },
	];

	return (
		<div className="language-selector">
			<p className="language-selector__title">{t('language')}</p>
			<div className="language-selector__actions">
				<Select
					value={currentLanguage}
					onChange={handleSelectLanguage}
					displayEmpty
					inputProps={{
						'aria-label': 'Without label',
						sx: {
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
						},
					}}
					sx={{ width: '100%', height: 32 }}
				>
					{countries.map((country) => (
						<MenuItem key={country.code} value={country.code}>
							{country.icon ? (
								<Avatar
									sx={{ width: 24, height: 24, marginRight: '10px' }}
									alt={country.code}
									src={country.icon}
								/>
							) : (
								<Avatar
									sx={{
										width: 24,
										height: 24,
										fontSize: '12px',
										marginRight: '10px',
									}}
								>
									{country.code.toUpperCase()}
								</Avatar>
							)}
							{country.name}
						</MenuItem>
					))}
				</Select>
			</div>
		</div>
	);
}
