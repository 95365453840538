const MenuStoreActions = (self) => ({
	openNavItem: (value) => {
		self.openedNavItem = value;
	},
	setOpenDrawer: (value) => {
		self.isDrawerOpen = value;
	},
	toggleForOpenDrawer: () => {
		self.isDrawerOpen = !self.isDrawerOpen;
	},
});

export default MenuStoreActions;
