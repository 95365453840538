import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';
import navigation, { menuItemsNoShops } from '../../common/MenuItems';
import Breadcrumbs from '../../@extended/Breadcrumbs';
import Footer from './Footer';
import Header from './Header';
import Drawer from './Drawer';
import { useStores } from '../../../stores/context';
import Loader from '../../common/Loader';
import useSocketNotifications from '../../../utils/useSocketNotifications';

export const MainLayout = observer(() => {
	const theme = useTheme();
	const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));

	const {
		shopsStore: { activeShop, shops, isLoaded },
		authStore: { user },
		menuStore: { isDrawerOpen, setOpenDrawer, toggleForOpenDrawer },
	} = useStores();

	useSocketNotifications(`${user?.id}-${activeShop?.id}`, activeShop?.id);

	const navigate = useNavigate();

	useEffect(() => {
		if (isLoaded && shops.length === 0) {
			navigate('/shops/add');
		}
	}, [isLoaded, shops, navigate]);

	useEffect(() => {
		setOpenDrawer(!matchDownXL);
	}, [matchDownXL]);

	return (
		<Box sx={{ display: 'flex', width: '100%', minWidth: '360px' }}>
			<Header open={isDrawerOpen} handleDrawerToggle={toggleForOpenDrawer} />
			<Drawer open={isDrawerOpen} handleDrawerToggle={toggleForOpenDrawer} />
			<Box
				component="main"
				sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}
			>
				<Toolbar />
				<Container
					maxWidth={false}
					sx={{
						px: { xs: 0, sm: 2 },
						position: 'relative',
						minHeight: 'calc(100vh - 110px)',
						display: 'flex',
						flexDirection: 'column',
						maxWidth: '2880px',
					}}
				>
					<Breadcrumbs
						navigation={activeShop.id ? navigation : menuItemsNoShops}
						title
						titleBottom
						card={false}
						divider={false}
					/>
					{activeShop.id || (isLoaded && shops.length === 0) ? (
						<Outlet />
					) : (
						<Loader />
					)}
					<Footer />
				</Container>
			</Box>
		</Box>
	);
});
