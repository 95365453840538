import { types } from 'mobx-state-tree';
import MenuStoreActions from './actions';

const MenuStore = types
	.model('MenuStore', {
		openedNavItem: types.optional(types.string, ''),
		isDrawerOpen: types.optional(types.boolean, false),
	})
	.actions(MenuStoreActions);

export default MenuStore;
