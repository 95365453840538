import { types } from 'mobx-state-tree';
import ShopsStoreActions from './actions';
import { Shop, PaymentMethod } from './model';

const ShopsStore = types
	.model('ShopsStore', {
		shops: types.optional(types.array(Shop), []),
		activeShop: types.optional(Shop, { id: 0 }),
		paymentMethods: types.optional(types.array(PaymentMethod), []),
		isLoaded: types.optional(types.boolean, false),
	})
	.actions(ShopsStoreActions);

export default ShopsStore;
