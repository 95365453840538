import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';
import { AppstoreAddOutlined } from '@ant-design/icons';
import IconButton from '../../../../../@extended/IconButton';

export function AddShop() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Box sx={{ flexShrink: 0, ml: 0.75 }}>
			<Stack
				direction="row"
				spacing={1}
				alignItems="center"
				sx={{ paddingRight: 0.5 }}
			>
				<IconButton
					color="secondary"
					variant="light"
					sx={{
						minWidth: 'min-content',
						display: 'flex',
						width: 'auto',
						justifyContent: 'space-between',
						color: 'text.primary',
					}}
					aria-label="Choose active shop"
					aria-haspopup="true"
					onClick={() => navigate('/shops/add')}
				>
					<AppstoreAddOutlined />
					<Typography
						sx={{
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							paddingX: '5px',
						}}
					>
						{t('menu-items.add-shop')}
					</Typography>
				</IconButton>
			</Stack>
		</Box>
	);
}
