import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Logo from '../../../../common/Logo';
import DrawerHeaderStyled from './DrawerHeaderStyled';

function DrawerHeader({ open }) {
	const theme = useTheme();

	return (
		<DrawerHeaderStyled theme={theme} open={open}>
			<Logo isIcon={!open} sx={{ width: open ? 'auto' : 35, height: 35 }} />
		</DrawerHeaderStyled>
	);
}

DrawerHeader.propTypes = {
	open: PropTypes.bool.isRequired,
};

export default DrawerHeader;
