// ==============================|| OVERRIDES - TEXT FIELD ||============================== //

export default function TextField() {
	return {
		MuiInputBase: {
			styleOverrides: {
				input: {
					'&[type="number"]': {
						MozAppearance: 'textfield',
						WebkitAppearance: 'textfield',
					},
					'&[type="number"]::-webkit-inner-spin-button': {
						WebkitAppearance: 'none',
					},
					'&[type="number"]::-webkit-outer-spin-button': {
						WebkitAppearance: 'none',
					},
				},
			},
		},
	};
}
