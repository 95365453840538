import axios from 'axios';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

export const protectedInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true,
});

const api = {
	auth: {
		getMe: () => protectedInstance.get('/auth/getMe'),
		register: (payload) => instance.post('/auth/register', payload),
		login: (payload) => instance.post('/auth/login', payload),
		logout: () => instance.get('/auth/logout'),
		removeHashedRefreshToken: () =>
			protectedInstance.get('/auth/removeHashedRefreshToken'),
		verifyEmail: () => protectedInstance.get('/auth/verifyEmail'),
		generateApiKey: (payload) =>
			protectedInstance.post('/auth/generateApiKey', payload),
		generateTelegramAuthLink: (payload) =>
			instance.post('/auth/generateTelegramAuthLink', payload),
		checkTelegramAuthorization: (payload) =>
			instance.post('/auth/checkTelegramAuthorization', payload),
		forgotPassword: (payload) => instance.post('/auth/forgotPassword', payload),
		changePassword: (payload) =>
			protectedInstance.patch('/auth/changePassword', payload),
		refreshToken: () => protectedInstance.get('/auth/refresh'),
	},
	users: {
		update: (payload) => protectedInstance.patch('/users', payload),
		updateActiveShopId: (payload) =>
			protectedInstance.patch('/users/updateActiveShopId', payload),
		statistic: () => protectedInstance.get('/users/statistic'),
	},
	shops: {
		getAllByUserId: () => protectedInstance.get('/shops/findAllByUserId'),
		create: (payload) => protectedInstance.post('/shops', payload),
		delete: (id) => protectedInstance.delete(`/shops/${id}`),
		update: (shopId, payload) =>
			protectedInstance.patch(`/shops/${shopId}`, payload),
		updateImages: (shopId, payload) =>
			protectedInstance.patch(`/shops/updateImages/${shopId}`, payload),
		updateAvatar: (shopId, payload) =>
			protectedInstance.patch(`/shops/updateAvatar/${shopId}`, payload),
	},
	categories: {
		getAll: (shopId) => protectedInstance.get(`/categories/${shopId}`),
		getAllWithPagination: (shopId, payload) =>
			protectedInstance.post(`/categories/shop/${shopId}`, payload),
		create: (shopId, payload) =>
			protectedInstance.post(`/categories/${shopId}`, payload),
		update: (id, payload) =>
			protectedInstance.patch(`/categories/${id}`, payload),
		delete: (id) => protectedInstance.delete(`/categories/${id}`),
		deleteMultiple: (payload) =>
			protectedInstance.post('/categories/deleteMultiple', payload),
	},
	attributes: {
		getAll: (shopId) => protectedInstance.get(`/attributes/${shopId}`),
		getAllWithPagination: (shopId, payload) =>
			protectedInstance.post(`/attributes/shop/${shopId}`, payload),
		getAllFormatted: (shopId) =>
			protectedInstance.get(`/attributes/formatted/${shopId}`),
		create: (shopId, payload) =>
			protectedInstance.post(`/attributes/${shopId}`, payload),
		update: (id, payload) =>
			protectedInstance.patch(`/attributes/${id}`, payload),
		delete: (id) => protectedInstance.delete(`/attributes/${id}`),
		deleteMultiple: (payload) =>
			protectedInstance.post('/attributes/deleteMultiple', payload),
	},
	attributesTemplates: {
		getAll: (shopId) =>
			protectedInstance.get(`/attributes-templates/${shopId}`),
		getAllWithPagination: (shopId, payload) =>
			protectedInstance.post(
				`/attributes-templates/withPagination/${shopId}`,
				payload,
			),
		create: (shopId, payload) =>
			protectedInstance.post(`/attributes-templates/${shopId}`, payload),
		update: (id, payload) =>
			protectedInstance.patch(`/attributes-templates/${id}`, payload),
		delete: (id) => protectedInstance.delete(`/attributes-templates/${id}`),
	},
	products: {
		getAll: (shopId, payload) =>
			protectedInstance.post(`/products/shop/${shopId}`, payload),
		create: (shopId, payload) =>
			protectedInstance.post('/products', { ...payload, shopId }),
		copy: (id, payload) =>
			protectedInstance.post(`/products/copy/${id}`, payload),
		update: (id, payload) =>
			protectedInstance.patch(`/products/${id}`, payload),
		updateProductAttributes: (id, payload) =>
			protectedInstance.post(`/products/${id}/attributes`, payload),
		updateImages: (productId, payload) =>
			protectedInstance.patch(`/products/updateImages/${productId}`, payload),
		delete: (id) => protectedInstance.delete(`/products/${id}`),
		deleteMultiple: (payload) =>
			protectedInstance.post('/products/deleteMultiple', payload),
		importProducts: (formData, shopId, fileType) =>
			protectedInstance.post(
				`products/importProducts/${shopId}/${fileType}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			),
		importProductsFromUrl: (formData, shopId, fileType) =>
			protectedInstance.post(
				`products/import-products-from-url/${shopId}/${fileType}`,
				formData,
			),
		exportProducts: (shopId) =>
			protectedInstance.get(`products/exportProducts/${shopId}`, {
				responseType: 'blob',
			}),
		cancelImport: (shopId) =>
			protectedInstance.post(`/products/cancel/${shopId}`),
	},
	orders: {
		getAll: (shopId, payload) =>
			protectedInstance.post(`/orders/shop/${shopId}`, payload),
		update: (shopId, id, payload) =>
			protectedInstance.post(`/orders/update/${shopId}/${id}`, payload),
		updateStatus: (id, payload) =>
			protectedInstance.post(`/orders/updateStatus/${id}`, payload),
		updateIsPayedStatus: (id, payload) =>
			protectedInstance.post(`/orders/updateIsPayedStatus/${id}`, payload),
		exportOrders: (shopId) =>
			protectedInstance.get(`orders/exportOrders/${shopId}`, {
				responseType: 'blob',
			}),
	},
	customers: {
		getOne: (id, shopId) =>
			protectedInstance.get(`/customers/getOneByShopId/${id}/${shopId}`),
		getAll: (shopId, payload) =>
			protectedInstance.post(`/customers/getAllByShopId/${shopId}`, payload),
		exportCustomers: (shopId) =>
			protectedInstance.get(`/customers/exportCustomers/${shopId}`, {
				responseType: 'blob',
			}),
	},
	shopParameters: {
		create: (payload) => protectedInstance.post('/shop-parameters', payload),
	},
	shopLocations: {
		create: (payload) =>
			protectedInstance.post('/shop-locations/bulkCreate', payload),
		updateImages: (locationId, payload) =>
			protectedInstance.patch(
				`/shop-locations/updateImages/${locationId}`,
				payload,
			),
		delete: (locationId) =>
			protectedInstance.delete(`/shop-locations/${locationId}`),
	},
	shopOnlinePaymentMethods: {
		create: (payload) =>
			protectedInstance.post(
				'/shop-online-payment-methods/bulkCreate',
				payload,
			),
		delete: (paymentMethodId) =>
			protectedInstance.delete(
				`/shop-online-payment-methods/${paymentMethodId}`,
			),
	},
	mailing: {
		create: (payload) =>
			protectedInstance.post('/mailing', payload, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
	},
	paymentMethods: {
		getAll: () => protectedInstance.get('/payment-methods'),
	},
	subscriptions: {
		getAll: () => protectedInstance.get('/subscriptions'),
		presignSubscription: (payload) =>
			protectedInstance.post('/subscriptions/presignSubscription', payload),
	},
	userSubscriptions: {
		getAllByUserId: (payload) =>
			protectedInstance.post('/user-subscriptions/getAllByUserId', payload),
		create: (payload) => protectedInstance.post('/user-subscriptions', payload),
	},
	images: {
		delete: (fileName, folder) =>
			protectedInstance.delete(`/minio-client/${fileName}`, {
				params: { folder },
			}),
		createImage: (formData) =>
			protectedInstance.post('/images', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
		deleteImage: (imageId) => protectedInstance.delete(`/images/${imageId}`),
	},
};

export function AxiosInterceptor({ children }) {
	const [retryCount, setRetryCount] = useState(0);

	useEffect(() => {
		const requestIntercept = protectedInstance.interceptors.request.use(
			(config) => {
				if (!config.headers.Authorization) {
					config.headers.Authorization = `Bearer ${localStorage.getItem(
						'accessToken',
					)}`;
				}

				return config;
			},
			(error) => Promise.reject(error),
		);

		const responseIntercept = protectedInstance.interceptors.response.use(
			(response) => response,
			async (error) => {
				const prevRequest = error?.config;
				if (
					error.response &&
					error.response.status === 401 &&
					!prevRequest.retry
				) {
					if (retryCount < 3) {
						try {
							const {
								data: { accessToken },
							} = await api.auth.refreshToken();
							localStorage.setItem('accessToken', accessToken);
							setRetryCount((prevCount) => prevCount + 1);
							prevRequest.headers.Authorization = `Bearer ${accessToken}`;
							prevRequest.retry = true;
							return protectedInstance(prevRequest);
						} catch (_error) {
							if (window.location.pathname !== '/login') {
								window.location.pathname = '/login';
							}
							return Promise.reject(_error);
						}
					} else {
						window.location.href = '/login';
					}
				} else if (
					error.response &&
					error.response.status === 403 &&
					error.response.data
				) {
					return Promise.reject(error.response.data);
				}
				return Promise.reject(error);
			},
		);

		return () => {
			protectedInstance.interceptors.request.eject(requestIntercept);
			protectedInstance.interceptors.response.eject(responseIntercept);
		};
	}, [retryCount]);

	return children;
}

AxiosInterceptor.propTypes = {
	children: PropTypes.node.isRequired,
};

export default api;
