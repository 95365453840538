import { toJS } from 'mobx';
import { flow, getEnv, getParent } from 'mobx-state-tree';

const CustomersStoreActions = (self) => ({
	getOne: flow(function* getOne(id, shopId) {
		const { api } = getEnv(self);
		const {
			ordersStore: { setIsLoaded },
		} = getParent(self);

		try {
			const { data } = yield api.customers.getOne(id, shopId);

			self.activeCustomer = data;
			setIsLoaded(true);
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	exportCustomers: flow(function* exportCustomers(shopId) {
		const { api } = getEnv(self);

		try {
			const { data } = yield api.customers.exportCustomers(shopId);

			return data;
		} catch (error) {
			throw new Error(error.response?.data?.message || error);
		}
	}),

	getAllWithPagination: flow(function* getAllWithPagination(shopId) {
		const { api } = getEnv(self);

		try {
			self.isLoading = true;
			const {
				data: { items, meta },
			} = yield api.customers.getAll(shopId, self.searchQuery);

			self.meta = {
				...meta,
				columnVisibility: toJS(self.searchQuery.columnVisibility),
				columnOrder: toJS(self.searchQuery.columnOrder),
				columnDimensions: toJS(self.searchQuery.columnDimensions),
				pinnedColumns: toJS(self.searchQuery.pinnedColumns),
			};
			self.items = items;
			self.isLoading = false;
			self.isLoaded = true;
		} catch (error) {
			throw new Error(error?.response?.data?.message || error);
		}
	}),

	setSearchQuery: (value) => {
		const query = { ...self.searchQuery, ...value };
		self.searchQuery = query;
		sessionStorage.setItem('customersSearchQuery', JSON.stringify(query));
	},
});

export default CustomersStoreActions;
